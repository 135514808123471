import React from 'react';
import { useFetchVehiclesByVariantQuery } from '~/api/controlDevice/ControlDevice.api';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import { iconsByVehicleVariant } from '~/features/base/constants/iconsByVehicleVariant';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';

const OverviewVehicleVariants: React.FC = () => {
    const { data: vehicleVariants, isLoading } = useFetchVehiclesByVariantQuery({});

    const vehicleVariantsSorted = vehicleVariants && Object.entries(vehicleVariants)
        .map(([key, value]) => ({ key, value }))
        .filter(count => count.key !== '')
        .reduce<{ key: string; value: number }[]>((acc, { key, value }) => {
            if (key.startsWith('fms')) {
                const existing = acc.find(item => item.key === 'fms');
                if (existing) {
                    existing.value += value;
                } else {
                    acc.push({ key: 'fms', value });
                }
            } else {
                acc.push({ key, value });
            }
            return acc;
        }, [])
        .sort((a, b) => b.value - a.value);

    const contentLoader =
        <div
            className={' display-flex justify-content-center  align-items-center   width-100pct padding-bottom-10 justify-content-between'}>
            <div>
                <ContentLoader className={'margin-right-10'} type={ContentLoader.CIRCLE} radius={10}/>
            </div>
            <div className={' display-flex '}>
                <ContentLoader className={'margin-right-10 '} width='15%'/>
                <ContentLoader width='25%'/>
            </div>
            <div>
                <ContentLoader className={'justify-content-end margin-right-10'} width='100%'/>
            </div>
        </div>;

    return (
        <StatsWidgets className=''>
            <StatsWidget style={{ border: 'none' }}>
                <StatsWidget.Header>
                    <div className=' text-start text-size-h5 text-bold'
                         style={{ fontFamily: 'MANEUROPECONDENSED' }}><FormattedMessage
                        id={'intl-msg:overview:vehicleDistributionByVariant'}/></div>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    <div style={{
                        overflowY: 'auto',
                        height: 200,
                        width: '100%',
                        scrollbarColor: 'transparent transparent',
                        padding: 10
                    }}>
                        {
                            !isLoading ? vehicleVariantsSorted?.map(({ key, value }, index) => (
                                    <div
                                        className={' display-flex width-100pct padding-bottom-10 justify-content-between'}>
                                        <div className={' display-flex '}>
                                            <span className='badge badge-info margin-right-10'>{index + 1}</span>
                                            <span
                                                className={`align-self-center ${iconsByVehicleVariant[key]}  margin-right-10`}>
                                            </span>
                                            <span className={''}
                                                  style={{ fontFamily: 'MANEUROPECONDENSED' }}>{key.toUpperCase()}</span>
                                        </div>
                                        <span className='justify-content-end margin-right-10 text-color-status-driving'>
                                            <FormattedNumber value={value}/>
                                         </span>
                                    </div>)) :

                                Array(6).fill(0, 0, 6).map((_) =>
                                    (contentLoader))

                        }
                    </div>
                </StatsWidget.Body>
            </StatsWidget>
        </StatsWidgets>);
};
export default OverviewVehicleVariants;
