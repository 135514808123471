import React from 'react';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import { useFetchVehiclesQuery } from '~/api/vehicles/VehicleManagement.api';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const OverviewTotalVehicles: React.FC = () => {
    const { data: vehicleList, isLoading } = useFetchVehiclesQuery({
        pageNumber: 0,
        pageSize: 1,
    });

    const renderValue = (number) => {
        if (isLoading) {
            return <div className={'max-width-100'}>
                <ContentLoader
                    width={'100%'}/></div>;
        }
        return <FormattedNumber value={number}/>;
    };
    return (
        <StatsWidgets className={'align-self-center'}>
            <StatsWidget style={{ border: 'none' }}>
                <StatsWidget.Header>
                    <span className={'text-size-h5 text-bold'}
                          style={{ fontFamily: 'MANEUROPECONDENSED' }}><FormattedMessage
                        id={'intl-msg:overview:totalVehicles'}/></span>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    <StatsWidgetNumber className='text-color-status-driving'
                                       value={renderValue(vehicleList?.totalElements)}/>
                </StatsWidget.Body>
            </StatsWidget>
        </StatsWidgets>
    );
};

export default OverviewTotalVehicles;
