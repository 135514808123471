// data
import { fetchUserSaga, showMissingUserRoleWarningDialogSaga } from '~/features/user/sagas/userSaga';
import {
    createControlDeviceSaga,
    fetchBlockedDeviceCountSaga,
    fetchControlDeviceCountSaga,
    fetchControlDeviceCountsByBaseSwInCustomerFleetSaga,
    fetchControlDeviceCountsByBaseSwSaga,
    fetchControlDeviceCountsByDistroInCustomerFleetSaga,
    fetchControlDeviceCountsByDistroSaga,
    fetchControlDeviceCountsByHwVariantInCustomerFleetSaga,
    fetchControlDeviceCountsByHwVariantSaga,
    fetchControlDeviceSaga,
    fetchDeviceStatusNamesSaga,
    fetchDeviceStatusTypesSaga,
    fetchErrorDeviceCountSaga,
    fetchFilteredControlDevicesSaga,
    fetchInCustomerFleetCountSaga,
    fetchTestDeviceCountSaga,
    fetchWarningDeviceCountSaga,
    triggerCheckForUpdateOnControlDeviceSaga,
    updateControlDeviceSaga,
} from '~/features/devices/sagas/controlDeviceSaga';
import { fetchControlDeviceWithSysParamsSaga } from '~/features/devices/sagas/controlDeviceSysParamsSaga';
import {
    fetchControlDeviceEligibleDeliverablesVersionsSaga,
    fetchControlDeviceInfoSaga,
    showControlDeviceDetailsInSidebar,
} from '~/features/devices/sagas/controlDeviceInfoSaga';
import {
    fetchControlDeviceOperationsSaga,
    fetchControlDeviceRolloutStatesSaga,
} from '~/features/devices/sagas/controlDeviceOperationsSaga';
import {
    downloadDeviceLogFileSaga,
    fetchDeviceLogsSaga,
    fetchDevicesWithLogsCountSaga,
    fetchDevicesWithLogsSaga,
} from '~/features/deviceLogs/sagas/deviceLogsSaga';

import {
    createDeviceActionSaga,
    deleteDeviceActionSaga,
    fetchDeviceActionCountSaga,
    fetchDeviceActionSaga,
    fetchDeviceActionsSaga,
    updateDeviceActionSaga,
} from '~/features/deviceLogs/sagas/deviceActionsSaga';

import { fetchBaseSwVersionsSaga, fetchEditableBaseSwVersionsSaga } from '~/features/baseSwVersions/sagas/baseSwSaga';
import {
    fetchDistroCountsByReleaseStateSaga,
    fetchDistroSaga,
    fetchDistroWithSysParamsSaga,
    fetchFilteredDistrosSaga,
} from '~/features/deliverables/features/distros/sagas/distroSaga';
import {
    copySysParamDefinitionsSaga,
    createSysParamDefinitionSaga,
    deleteSysParamDefinitionSaga,
    fetchSysParamDefinitionsSaga,
    updateSysParamDefinitionSaga,
} from '~/features/sysParams/sagas/sysParamDefinitionsSaga';
import {
    createSysParamDefaultValueSaga,
    deleteSysParamDefaultValueSaga,
    fetchSysParamDefaultValuesSaga,
    updateSysParamDefaultValueSaga,
} from '~/features/sysParams/sagas/sysParamDefaultValuesSaga';
import {
    createSysParamSystemValueSaga,
    deleteSysParamSystemValueSaga,
    fetchSysParamSystemValuesSaga,
    updateSysParamSystemValueSaga,
} from '~/features/sysParams/sagas/sysParamSystemValuesSaga';
import { fetchSysParamDeviceValuesSaga } from '~/features/sysParams/sagas/sysParamDeviceValuesSaga';
import {
    deleteSysParamSaga,
    exportSysParamsSaga,
    importSysParamsSaga,
} from '~/features/sysParams/sagas/sysParamsSaga';

import {

    fetchPackageCountSaga,
    fetchPackagesSaga,
} from '~/features/deliverables/features/packages/sagas/packageSaga';
import {

    fetchPackageDeliverableCountSaga,
    fetchPackageDeliverableSaga,
    fetchPackageVersionsSaga,
} from '~/features/deliverables/features/packages/sagas/packageDeliverableSaga';
import {
    addDeviceToGroupSaga,
    createGroupSaga,
    deleteGroupSaga,
    fetchDeviceGroupsSaga,
    fetchFilteredGroupsSaga,
    fetchGroupSaga,
    fetchReferencesOfGroupSaga,
    removeDeviceFromGroupSaga,
    updateDeviceGroupsSaga,
    updateGroupSaga,
} from '~/features/groups/sagas/groupSaga';
import {
    fetchSerialNumbersOfGroupSaga,
} from '~/features/groups/sagas/groupDevicesAssignedSaga';
import {
    exportFilteredUpdateEventsSaga,
    fetchFilteredUpdateEventsSaga,
    fetchUpdateEventCountSaga,
    fetchUpdateEventCountsByTimeIntervalSaga,
    fetchUpdateEventReportSaga,
    fetchUpdateEventSuccessAndFailedSaga,
} from '~/features/updateEvents/sagas/updateEventSaga';

import { fetchAppsSaga } from '~/features/apps/sagas/appsSaga';
// ui
import { followRouteSaga } from '~/features/base/sagas/ui/routeSaga';
import { triggerDataFetcherSaga } from '~/features/base/sagas/ui/fetchDataSaga';

import { exportDevicesSaga } from '~/features/devices/sagas/exportSaga';

import {
    fetchHomeDataSaga,
} from '~/features/oldOverview/sagas/homeSaga';

import {showErrorMessagesSaga, showSuccessMessagesSaga} from '~/features/base/sagas/ui/notificationsSaga';

import { showControlDeviceEditorSaga } from '~/features/devices/sagas/controlDeviceEditorSaga';

import {
    showDeviceActionEditorSaga,
    showLogRequestDeletionDialogSaga,
} from '~/features/deviceLogs/sagas/deviceActionEditorSaga';

import {
    showCopySysParamDefinitionsDialogSaga,
    showImportSysParamDefinitionsDialogSaga,
} from '~/features/deliverables/features/distros/sagas/distroEditorSaga';
import {
    showSysParamDefinitionDeletionDialogSaga,
    showSysParamDefinitionEditorSaga,
} from '~/features/sysParams/sagas/sysParamDefinitionEditorSaga';
import {
    showSysParamSystemValueDeletionDialogSaga,
    showSysParamSystemValueEditorSaga,
} from '~/features/sysParams/sagas/sysParamSystemValueEditorSaga';

import {
    fetchSerialNumbersAndOpenEditorOfGroupSaga,
    showGroupDeletionDialogSaga,
    showGroupEditorDialogSaga,
} from '~/features/groups/sagas/groupEditorSaga';
import {
    showFileDeletionDialogSaga,
    showFileEditorDialogSaga,
} from '~/features/deliverables/features/files/sagas/fileEditorSaga';

import { showUpdateEventReportDialogSaga } from '~/features/updateEvents/sagas/updateEventReportSaga';
import {
    addNewContextSaga,
    createDeliverableSaga,
    deleteDeliverableSaga,
    fetchDeliverablesUserInfoSaga,
    fetchFilteredDeliverablesSaga,

} from '~/features/artifacts/sagas/deliverableSaga';
import {
    deleteDeliverableFileSaga,
    downloadDeliverableFileSaga,
    fetchDeliverableFilesSaga,
    uploadDeliverableFileSaga,
} from '~/features/artifacts/sagas/deliverableFileSaga';
import { uploadRequestWatcherSaga } from '~/features/base/sagas/fileUploadSaga';
import {
    showDeliverableDeletionDialogSaga,
    showDeliverableEditorDialogSaga,
    showDeliverableFileDeletionDialogSaga,
} from '~/features/artifacts/sagas/deliverableEditorSaga';
import { fetchHwVariantsSaga } from '~/features/hwVariants/sagas/hwVariantsSaga';
import {
    fetchStateNotificationsOfDeviceSaga,
    requestNewStateNotificationSaga,
    setSystemStateColumnSettingsSaga,
} from '~/features/systemState/sagas/systemStateSaga';
import {
    fetchContextDetailsSaga,
    showDeliverablePermissionDialogSaga,
    updateContextOwnerSaga,
} from '~/features/artifacts/sagas/deliverablePermissionSaga';
import { fetchVehicleSparePartNumbersSaga } from '~/features/vehicleSparePartNumbers/sagas/vehicleSparePartNumbersSaga';
import {
    createJiraIssueSaga,
    findJiraIssuesSaga,
    showJiraIssueEditorSaga,
} from '~/features/atlassian/sagas/atlassianJiraSaga';
import { fetchVehicleVariantsSaga } from '~/features/vehicleVariants/sagas/vehicleVariantsSaga';
import {
    addNewContextSagaNeo,
    createDeliverableSagaNeo,
    deleteDeliverableSagaNeo,
    deleteDeliverableVersionSagaNeo,
    fetchDeliverablesUserInfoSagaNeo,
    fetchFilteredDeliverableIdsSagaNeo,
    fetchAllDeliverableIdsSagaNeo,
    fetchFilteredDeliverablesSagaNeo,
    updateDeliverableSaga,
    fetchLiveDeliverablesSaga, fetchDeliverableWhitelistingSaga,
} from '~/features/deliverables/sagas/deliverableSaga';
import {
    showDeliverableDeletionDialogSagaNeo,
    showDeliverableEditorDialogSagaNeo,
    showDeliverableFileDeletionDialogSagaNeo,
    showDeliverableVersionDeletionDialogSagaNeo,
} from '~/features/deliverables/sagas/deliverableEditorSaga';
import { showDeliverableVersionEditorDialogSaga } from '~/features/deliverables/sagas/deliverableVersionEditorSaga';
import {
    fetchDeliverableFilesSagaNeo,
    uploadDeliverableFileSagaNeo,
} from '~/features/deliverables/sagas/deliverableFileSaga';
import {
    showArtifactDeletionDialogSaga,
    showArtifactEditorDialogSaga,
    showArtifactFileDeletionDialogSaga,
} from '~/features/deliverables/features/artifacts/sagas/artifactEditorSaga';
import {
    createArtifactSaga,
    deleteArtifactSaga,
    fetchFilteredArtifactsSaga,
} from '~/features/deliverables/features/artifacts/sagas/artifactSaga';
import {
    deleteArtifactFileSaga,
    downloadArtifactFileSaga,
    fetchArtifactFilesSaga,
    uploadArtifactFileSaga,
} from '~/features/deliverables/features/artifacts/sagas/artifactFileSaga';
import {
    addDeviceToDeliverableWhitelistSaga,
    removeDeviceFromDeliverableWhitelistSaga,
    revokeUninstallPackageDeliverableSaga,
    showControlDeviceQuickWhitelistingSaga,
} from '~/features/devices/sagas/controlDeviceQuickWhitelistingSaga';
import {
    fetchContextDetailsSagaNeo,
    showDeliverablePermissionDialogSagaNeo,
    updateContextOwnerSagaNeo,
} from '~/features/deliverables/sagas/deliverablePermissionSaga';
import {
    fetchControlDeviceReferencedDeliverablesSaga,
    showControlDeviceAssociatedDeliverablesSaga,
} from '~/features/devices/sagas/controlDeviceAssociatedDeliverablesSaga';
import {
    fetchReportsBillingChartsDataSaga,
    removeReportsBillingChartsDataSaga,
} from '~/features/reportsbilling/sagas/reportsBillingSagas';
import { fetchDefaultNetworkCodeListSaga } from '~/features/base/sagas/ui/fetchNetworkCodeListSaga';
import { fetchFilteredVehiclesSaga } from '~/features/oldVehicles/sagas/vehiclesSaga';
import { showVehicleDetailsInSidebar } from '~/features/oldVehicles/sagas/vehicleInfoSaga';
import {
    fetchDeviceCountsByCountriesSaga,
    fetchDeviceCountsByVehiclesSaga,
} from '~/features/devices/sagas/deviceDistributionsSaga';
import { fetchDeliverableHistorySaga } from '~/features/deliverables/sagas/deliverableHistorySaga';

const sagas = [
    // data
    fetchUserSaga,
    showMissingUserRoleWarningDialogSaga,
    fetchControlDeviceCountSaga,
    fetchTestDeviceCountSaga,
    fetchBlockedDeviceCountSaga,
    fetchWarningDeviceCountSaga,
    fetchErrorDeviceCountSaga,
    fetchInCustomerFleetCountSaga,
    fetchControlDeviceCountsByBaseSwSaga,
    fetchControlDeviceCountsByDistroSaga,
    fetchControlDeviceCountsByHwVariantSaga,
    fetchControlDeviceCountsByBaseSwInCustomerFleetSaga,
    fetchControlDeviceCountsByDistroInCustomerFleetSaga,
    fetchControlDeviceCountsByHwVariantInCustomerFleetSaga,
    fetchFilteredControlDevicesSaga,
    fetchFilteredVehiclesSaga,
    showVehicleDetailsInSidebar,
    fetchSerialNumbersOfGroupSaga,
    fetchDeliverableWhitelistingSaga,
    fetchControlDeviceSaga,
    createControlDeviceSaga,
    updateControlDeviceSaga,
    triggerCheckForUpdateOnControlDeviceSaga,
    fetchControlDeviceWithSysParamsSaga,
    fetchControlDeviceInfoSaga,
    showControlDeviceDetailsInSidebar,
    fetchControlDeviceEligibleDeliverablesVersionsSaga,
    fetchControlDeviceOperationsSaga,
    fetchControlDeviceRolloutStatesSaga,
    fetchDevicesWithLogsCountSaga,
    fetchDevicesWithLogsSaga,
    fetchDeviceLogsSaga,
    downloadDeviceLogFileSaga,
    fetchDeviceActionCountSaga,
    fetchDeviceActionsSaga,
    fetchDeviceActionSaga,
    createDeviceActionSaga,
    updateDeviceActionSaga,
    deleteDeviceActionSaga,
    fetchBaseSwVersionsSaga,
    fetchEditableBaseSwVersionsSaga,
    fetchHwVariantsSaga,
    fetchDeviceStatusNamesSaga,
    fetchDeviceStatusTypesSaga,
    fetchVehicleSparePartNumbersSaga,
    fetchVehicleVariantsSaga,
    fetchDistroCountsByReleaseStateSaga,
    fetchFilteredDistrosSaga,
    fetchDistroSaga,
    fetchDistroWithSysParamsSaga,
    fetchDeliverableHistorySaga,
    fetchSysParamDefinitionsSaga,
    createSysParamDefinitionSaga,
    updateSysParamDefinitionSaga,
    deleteSysParamDefinitionSaga,
    copySysParamDefinitionsSaga,
    fetchSysParamDefaultValuesSaga,
    createSysParamDefaultValueSaga,
    updateSysParamDefaultValueSaga,
    deleteSysParamDefaultValueSaga,
    fetchSysParamSystemValuesSaga,
    createSysParamSystemValueSaga,
    updateSysParamSystemValueSaga,
    deleteSysParamSystemValueSaga,
    fetchSysParamDeviceValuesSaga,
    exportSysParamsSaga,
    importSysParamsSaga,
    deleteSysParamSaga,
    fetchStateNotificationsOfDeviceSaga,
    requestNewStateNotificationSaga,
    setSystemStateColumnSettingsSaga,
    fetchPackageCountSaga,
    fetchPackagesSaga,
    fetchPackageDeliverableCountSaga,
    fetchPackageDeliverableSaga,
    fetchPackageVersionsSaga,
    fetchFilteredGroupsSaga,
    fetchDeviceGroupsSaga,
    fetchGroupSaga,
    fetchReferencesOfGroupSaga,
    createGroupSaga,
    updateGroupSaga,
    addDeviceToGroupSaga,
    removeDeviceFromGroupSaga,
    deleteGroupSaga,
    updateDeviceGroupsSaga,
    fetchUpdateEventCountSaga,
    fetchUpdateEventSuccessAndFailedSaga,
    fetchUpdateEventCountsByTimeIntervalSaga,
    fetchUpdateEventReportSaga,
    fetchFilteredUpdateEventsSaga,
    exportFilteredUpdateEventsSaga,
    fetchFilteredDeliverablesSaga,
    fetchFilteredDeliverablesSagaNeo,
    fetchFilteredDeliverableIdsSagaNeo,
    fetchAllDeliverableIdsSagaNeo,
    uploadDeliverableFileSaga,
    uploadDeliverableFileSagaNeo,
    fetchDeliverableFilesSaga,
    fetchDeliverableFilesSagaNeo,
    deleteDeliverableFileSaga,
    downloadDeliverableFileSaga,
    fetchDeliverablesUserInfoSaga,
    createDeliverableSaga,
    createDeliverableSagaNeo,
    updateDeliverableSaga,
    deleteDeliverableSaga,
    deleteDeliverableSagaNeo,
    deleteDeliverableVersionSagaNeo,
    fetchDeliverablesUserInfoSagaNeo,
    addNewContextSaga,
    addNewContextSagaNeo,
    fetchLiveDeliverablesSaga,
    fetchAppsSaga,
    uploadRequestWatcherSaga,
    fetchReportsBillingChartsDataSaga,
    removeReportsBillingChartsDataSaga,
    // ui
    followRouteSaga,
    triggerDataFetcherSaga,
    exportDevicesSaga,
    fetchHomeDataSaga,
    fetchDeviceCountsByCountriesSaga,
    fetchDeviceCountsByVehiclesSaga,
    showArtifactEditorDialogSaga,
    showArtifactDeletionDialogSaga,
    showArtifactFileDeletionDialogSaga,
    createArtifactSaga,
    deleteArtifactSaga,
    fetchFilteredArtifactsSaga,
    uploadArtifactFileSaga,
    fetchArtifactFilesSaga,
    deleteArtifactFileSaga,
    downloadArtifactFileSaga,
    showControlDeviceEditorSaga,
    addDeviceToDeliverableWhitelistSaga,
    removeDeviceFromDeliverableWhitelistSaga,
    revokeUninstallPackageDeliverableSaga,
    showControlDeviceQuickWhitelistingSaga,
    showControlDeviceAssociatedDeliverablesSaga,
    fetchControlDeviceReferencedDeliverablesSaga,
    showDeviceActionEditorSaga,
    showLogRequestDeletionDialogSaga,
    showCopySysParamDefinitionsDialogSaga,
    showImportSysParamDefinitionsDialogSaga,
    showSysParamDefinitionEditorSaga,
    showSysParamDefinitionDeletionDialogSaga,
    showSysParamSystemValueEditorSaga,
    showSysParamSystemValueDeletionDialogSaga,
    showErrorMessagesSaga,
    showSuccessMessagesSaga,
    showGroupEditorDialogSaga,
    showGroupDeletionDialogSaga,
    fetchSerialNumbersAndOpenEditorOfGroupSaga,
    showFileEditorDialogSaga,
    showFileDeletionDialogSaga,
    showDeliverableEditorDialogSaga,
    showDeliverableEditorDialogSagaNeo,
    showDeliverableVersionEditorDialogSaga,
    showDeliverableDeletionDialogSaga,
    showDeliverableDeletionDialogSagaNeo,
    showDeliverableVersionDeletionDialogSagaNeo,
    showDeliverableFileDeletionDialogSaga,
    showDeliverableFileDeletionDialogSagaNeo,
    showDeliverablePermissionDialogSaga,
    showDeliverablePermissionDialogSagaNeo,
    updateContextOwnerSaga,
    updateContextOwnerSagaNeo,
    fetchContextDetailsSaga,
    fetchContextDetailsSagaNeo,
    showUpdateEventReportDialogSaga,
    showJiraIssueEditorSaga,
    findJiraIssuesSaga,
    createJiraIssueSaga,
    fetchDefaultNetworkCodeListSaga,
];

export default sagas;
