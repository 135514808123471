import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import size from 'lodash/fp/size';
import { FlagLabel } from '~/features/oldOverview/components/FlagLabel';
import { VehicleLabel } from '~/features/oldOverview/components/VehicleLabel';
import PieChart from '@rio-cloud/rio-uikit/PieChart';

const width = '100%';
const height = 400;

export class DonutOld extends PureComponent {
    constructor(props) {
        super(props);
        this.onChartClick = this.onChartClick.bind(this);
        this.state = {
            activeIndex: 0,
        };
    }

    onChartClick(item) {
        this.props.onClick(this.props.tileDescriptor, item.label);
    }

    setCustomLabels(item) {
        return `${item.name}: ${(item.percent * 100).toFixed(2)} %`;
    }

    checkLabel() {
        const { distributionType } = this.props.tileDescriptor;
        if (distributionType === 'country') {
            return <FlagLabel/>;
        } else if (distributionType === 'vehicles') {
            return <VehicleLabel/>;
        } else {
            return this.setCustomLabels;
        }
    }

    render() {
        const { data } = this.props;
        const animationDuration = this.props.animationDuration === 0 ? undefined : 300;
        const setLabel = this.checkLabel();

        return (
            size(data) ? (
                <PieChart
                    data={data.filter(entry => entry.value)}
                    dataKey={entry => entry.value}
                    nameKey={'label'}
                    color={(entry) => entry.colorChart}
                    width={width}
                    height={height}
                    tooltip={false}
                    legend={false}
                    innerLabels={false}
                    innerRadius={70}
                    outerRadius={120}
                    pieOptions={{
                        onClick: this.onChartClick,
                        label: setLabel,
                        labelLine: true,
                        minAngle: 5,
                        paddingAngle: 1,
                        animationDuration: animationDuration,
                    }}
                />
            ) : null
        );
    }
}

export default DonutOld;

DonutOld.propTypes = {
    // props
    data: PropTypes.array,
    tileDescriptor: PropTypes.object,
    // functions
    onClick: PropTypes.func,
};
