import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { env } from '~/env';
import { colors, otherColor, colorsChart, otherColorChart } from '~/features/base/constants/chartColors';
import uid from '~/features/base/utils/uid';
import size from 'lodash/fp/size';
import getOr from 'lodash/fp/getOr';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import orderBy from 'lodash/fp/orderBy';
import DonutTableTile from '~/features/oldOverview/components/DonutTableTile';
import { iconsByVehicleVariant } from '~/features/base/constants/iconsByVehicleVariant';

export class DistributionTile extends Component {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            view: 'piechart',
            searchValue: '',
        };
        this.onSearchValueChange = this.onSearchValueChange.bind(this);

    }

    onTileClick = () => {
        this.setState({
            view: this.state.view === 'piechart' ? 'table' : 'piechart',
        });
    };
    onSearchValueChange = (searchValue) => {
        this.setState({
            searchValue,
        });
    };
    prepareDevicesByCountries = (rawData) => {
        const { region, networkCodes } = env.runtimeConfig;
        const data = [];
        let topData = {}, tableData = {};
        const networkCodeKeys = Object.keys(networkCodes);
        Object.keys(rawData).forEach(nc => {
            const ncKey = nc === '000' ? '000' : nc === '' ? 'empty' : nc;
            if (networkCodeKeys.includes(ncKey)) {
                let name = 'other', iso2 = 'xx', code = 0;
                code = ncKey;
                name = networkCodes[ncKey].name;
                iso2 = networkCodes[ncKey].iso2;
                const classes = `fi fi-${iso2}`;
                data.push({
                    code,
                    name,
                    label: name,
                    classes,
                    value: rawData[ncKey],
                });
            }
        });
        let eu = data.filter(x => x.code !== '000' && x.code !== '');
        eu = orderBy(['value'], ['desc'], eu);
        const zero = data.filter(x => x.code === '000');
        const empty = data.filter(x => x.code === '');

        topData = eu.slice(0, 7);
        for (let i = 0; i < topData.length; i++) {
            topData[i].color = colors[i];
            topData[i].colorChart = colorsChart[i];
        }
        const other = eu.slice(7, data.length);
        const otherDataValue = other.reduce(function(acc, obj) {
            return acc + obj.value;
        }, 0);
        if (otherDataValue > 0) {
            const otherClasses = region === 'EU' ? 'fi fi-eu' : 'fi fi-xx';
            const otherName = region === 'EU' ? 'EU' : 'LATAM';
            topData.push({
                code: 0,
                name: otherName,
                label: otherName,
                classes: otherClasses,
                value: otherDataValue,
                color: otherColor,
                colorChart: otherColorChart,
            });
        }
        tableData = [...eu, ...zero, ...empty];
        return { topData, tableData };
    };
    prepareDevicesByVehiclesEU = (rawData) => {
        let topData;
        let tableData;
        let vehicleData = [];
        const rawDataKeys = Object.keys(rawData);
        rawDataKeys.forEach(key => {
            if (key === 'e4c') {
                vehicleData.push({
                    id: key,
                    code: key,
                    name: key,
                    value: rawData.e4c,
                    label: 'E4C',
                    classes: iconsByVehicleVariant[key],
                });
            } else if (key === 'nhmr') {
                vehicleData.push({
                    id: key,
                    code: key,
                    name: key,
                    value: rawData.nhmr,
                    label: 'TG3',
                    classes: iconsByVehicleVariant[key],
                });
            } else if (key === 'tgx') {
                vehicleData.push({
                    id: key,
                    code: key,
                    name: key,
                    value: rawData.tgx,
                    label: 'TG2',
                    classes: iconsByVehicleVariant[key],
                });
            } else if (key === 'fms1' || key === 'fms2' || key === 'fms3' || key === 'fms4') {
                const item = vehicleData.filter(i => i.id === 'fms');
                if (item[0]) {
                    item[0].value += rawData[key];
                } else {
                    vehicleData.push({
                        id: 'fms',
                        code: key,
                        name: key,
                        value: rawData[key],
                        label: 'FMS',
                        classes: iconsByVehicleVariant['fms'],
                    });
                }
            }
        });
        vehicleData = orderBy(['value'], ['desc'], vehicleData);
        for (let i = 0; i < vehicleData.length; i++) {
            vehicleData[i].color = colors[i];
            vehicleData[i].colorChart = colorsChart[i];
        }
        topData = [...vehicleData];
        const valueOfOthers = (rawData.ican ? rawData.ican : 0) +
            (rawData.unknown ? rawData.unknown : 0) +
            (rawData.vw ? rawData.vw : 0) +
            (rawData[''] ? rawData[''] : 0);
        if (valueOfOthers > 0) {
            topData.push({
                id: 'other',
                code: 'other',
                name: 'other',
                classes: iconsByVehicleVariant.delivery,
                label: 'Other',
                value: valueOfOthers,
                color: otherColor,
                colorChart: otherColorChart,
            });
        }
        tableData = [...vehicleData];
        const otherKeys = ['ican', 'unknown', 'vw', ''];
        for (let i = 0; i < otherKeys.length; i++) {
            const keys = Object.keys(rawData);
            if (keys.includes(otherKeys[i])) {
                const key = otherKeys[i] === '' ? 'empty' : otherKeys[i];
                tableData.push({
                    id: key,
                    code: key,
                    name: key,
                    classes: key === 'ican' ? iconsByVehicleVariant.ican : iconsByVehicleVariant.other,
                    label: key.toUpperCase(),
                    value: rawData[otherKeys[i]],
                    color: otherColor,
                    colorChart: otherColorChart,
                });
            }
        }
        return {
            topData,
            tableData,
        };
    };
    addVehicleData = (id, key, value, label, classes) => {
        return {
            id,
            code: key,
            name: key,
            value,
            label,
            classes,
        };
    };
    prepareDevicesByVehiclesLATAM = (rawData) => {
        let topData = [];
        let tableData = [];
        let valueOfOthers = 0;
        let vehicleData = [];
        const regionVehicleKeys = ['ican', 'vw'];
        const regionDataKeys = Object.keys(pick(regionVehicleKeys, rawData));
        const otherRawDataKeys = Object.keys(omit(regionVehicleKeys, rawData));
        regionDataKeys.forEach(key => {
            vehicleData.push(this.addVehicleData(
                key,
                key,
                rawData[key],
                key === 'ican' ? 'TG' : 'VW',
                iconsByVehicleVariant[key],
            ));
        });
        otherRawDataKeys.forEach(key => {
            valueOfOthers += rawData[key];
        });
        vehicleData = orderBy(['value'], ['desc'], vehicleData);
        for (let i = 0; i < vehicleData.length; i++) {
            vehicleData[i].color = colors[i];
            vehicleData[i].colorChart = colorsChart[i];
        }
        topData = [...vehicleData];
        if (valueOfOthers > 0) {
            topData.push(this.addVehicleData(
                'other',
                'other',
                valueOfOthers,
                'Other',
                iconsByVehicleVariant.delivery,
            ));
            topData[topData.length - 1].color = otherColor;
            topData[topData.length - 1].colorChart = otherColorChart;
        }
        tableData = [...vehicleData];
        let fmsValue = 0;
        otherRawDataKeys.forEach(key => {
            if (key.includes('fms')) {
                fmsValue += rawData[key];
            } else {
                let data = this.addVehicleData(
                    key,
                    key,
                    rawData[key],
                    key === '' ? 'EMPTY' : key.toUpperCase(),
                    key === 'ican' ? iconsByVehicleVariant.ican : iconsByVehicleVariant.other,
                );
                data.color = otherColor;
                data.colorChart = otherColorChart;
                tableData.push(data);
            }
        });
        tableData.push(this.addVehicleData('fms', 'fms', fmsValue, 'FMS', iconsByVehicleVariant.other));
        return {
            topData,
            tableData,
        };
    };

    render() {
        const { tileDescriptor } = this.props;
        const { region } = env.runtimeConfig;
        const rawData = getOr(0, tileDescriptor.dataPath, this.props.props);
        const distributionType = tileDescriptor.distributionType;
        const { view, searchValue } = this.state;
        let data;
        if (rawData) {
            data = distributionType === 'country'
                ? this.prepareDevicesByCountries(rawData)
                : region === 'EU'
                    ? this.prepareDevicesByVehiclesEU(rawData)
                    : this.prepareDevicesByVehiclesLATAM(rawData);
            data.tableData = orderBy(['value'], ['desc'], data.tableData);
        }
        return (
            <>
                {size(data) > 0 ?
                    <DonutTableTile key={tileDescriptor.id}
                                    label={tileDescriptor.label}
                                    topData={data.topData}
                                    tableData={data.tableData}
                                    view={view}
                                    searchValue={searchValue}
                                    onSearchValueChange={this.onSearchValueChange}
                                    tileDescriptor={tileDescriptor}
                                    onTileClick={this.onTileClick}/>
                    : <DonutTableTile key={tileDescriptor.id}
                                      label={tileDescriptor.label}
                                      topData={[]}
                                      tableData={[]}
                                      view={view}
                                      tileDescriptor={tileDescriptor}
                                      onClick={this.onTileClick}/>
                }
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (JSON.stringify(this.props.props.deviceCountsByCountry) !== JSON.stringify(
            nextProps.props.deviceCountsByCountry)) {
            return true;
        }
        if (JSON.stringify(this.props.props.deviceCountsByVehicle) !== JSON.stringify(
            nextProps.props.deviceCountsByVehicle)) {
            return true;
        }
        if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
            return true;
        }
        return false;
    }
}

export default DistributionTile;

DistributionTile.propTypes = {
    // props
    label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    data: PropTypes.number,
    tileDescriptor: PropTypes.object,
    // functions
    onClick: PropTypes.func,
};
