import PropTypes from 'prop-types';
import React from 'react';

import Tile from '~/features/oldOverview/components/Tile';
import Legend from '~/features/oldOverview/components/Legend';
import DonutOld from '~/features/oldOverview/components/DonutOld';
import FlagsLegend from '~/features/oldOverview/components/FlagsLegend';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import { DistributionTileTable } from '~/features/oldOverview/components/DistributionTileTable';
import VehiclesLegend from '~/features/oldOverview/components/VehiclesLegend';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest z-index-5';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

export const DonutTableTile = ({
    label,
    topData,
    tableData,
    tileDescriptor,
    onClick,
    onTileClick,
    view,
    searchValue,
    onSearchValueChange,
}) => {
    const viewType = view === 'piechart'
        ? 'display-flex justify-content-center height-100pct align-items-end'
        : 'display-flex justify-content-center height-100pct align-items-start';
    const items = tileDescriptor.distributionType === 'country'
        ? {
            legend: <FlagsLegend route={tileDescriptor.link} query={tileDescriptor.query} data={topData}/>,
            msg: <FormattedMessage id='intl-msg:overview.countryTile.changeTableView'
                                   values={{ region: tileDescriptor.region }}/>,
        }
        : tileDescriptor.distributionType === 'vehicles'
            ? {
                legend: <VehiclesLegend route={tileDescriptor.link} query={tileDescriptor.query} data={topData}/>,
                msg: <FormattedMessage id='intl-msg:overview.vehicleTile.changeTableView'
                                       values={{ region: tileDescriptor.region }}/>,
            }
            : {
                legend: <Legend route={tileDescriptor.link} query={tileDescriptor.query} data={topData}/>,
                msg: <FormattedMessage id={''}/>,
            };

    return (
        <div className='col-xs-8 col-sm-6 col-md-4 width-100pct'>
            <div className='bg-white bg-tile height-500'>
                {view === 'piechart' &&
                    <div className='position-relative'>
                        <div
                            className='position-absolute padding-top-10 padding-left-10 padding-right-10 width-100pct z-index-1'>
                            {items.legend}
                        </div>
                    </div>
                }
                <div className={viewType}>
                    {(tileDescriptor.distributionType === 'country' || tileDescriptor.distributionType === 'vehicles')
                        &&
                        <div
                            className='width-100pct height-100pct position-absolute display-flex justify-content-end align-items-end padding-bottom-10 padding-right-20 '>
                        <span className='btn btn-default btn-link z-index-5 text-size-16' onClick={onTileClick}>
                            <OverlayTrigger placement='top' overlay={
                                <Tooltip id='carousel-tile-tooltip' className='top-right'>
                                    {view === 'piechart'
                                        ? items.msg
                                        : <FormattedMessage id={'intl-msg:overview.changePiechartView'}/>
                                    }
                                </Tooltip>}>
                                <i className='rioglyph rioglyph-cards-grid text-size-200pct margin-right-20'/>
                            </OverlayTrigger>
                        </span>
                        </div>
                    }
                    {view === 'piechart' &&
                        <DonutOld tileDescriptor={tileDescriptor} data={topData} onClick={onClick}/>
                    }
                    {view === 'table' &&
                        <DistributionTileTable tileDescriptor={tileDescriptor} tableData={tableData}
                                               searchValue={searchValue} onSearchValueChange={onSearchValueChange}/>
                    }
                </div>
                <div className='position-relative'>
                    <div className={labelWrapperClasses}>
                        <span className={labelClasses}>{label}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DonutTableTile;

DonutTableTile.propTypes = {
    // props
    label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    topData: PropTypes.array,
    tableData: PropTypes.array,
    tileDescriptor: PropTypes.object,
    view: PropTypes.string,
    searchValue: PropTypes.string,
    // functions
    onClick: PropTypes.func,
    onTileClick: PropTypes.func,
    searchValueChange: PropTypes.func,
};
