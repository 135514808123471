import React, { useEffect, useState } from 'react';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import StatsWidgetSpacer from '@rio-cloud/rio-uikit/StatsWidgetSpacer';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useLazyFetchUpdatesCountQuery } from '~/api/updateHistory/UpdateHistoryUpdate.api';
import { UpdateHistorySearchCriteriaOutcomeEnum } from '~/api/updateHistory/update-history-search-criteria';
import TagSelector from '~/features/base/components/TagSelector';
import { Link } from 'react-router-dom';
import { EVENTS_PATH, OVERVIEW_PATH } from '~/features/base/constants/routes';

const timeframes = {
    all: undefined,
    lastMonth: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    lastWeek: new Date(new Date().setDate(new Date().getDate() - 7)),
    today: new Date(new Date().setHours(0, 0, 0, 0))
};

const timeFrameOptions = [
    { key: 'all', label: <FormattedMessage id={'intl-msg:all'}/> },
    { key: 'lastMonth', label: <FormattedMessage id={'intl-msg:timeOptions.lastMonth'}/> },
    { key: 'lastWeek', label: <FormattedMessage id={'intl-msg:timeOptions.lastWeek'}/> },
    { key: 'today', label: <FormattedMessage id={'intl-msg:timeOptions.today'}/> },
];
const updateEventsTypes = [
    {
        key: 'success',
        label: <FormattedMessage id={'intl-msg:deviceUpdateOutcome.success'}/>,
        outcome: UpdateHistorySearchCriteriaOutcomeEnum.SUCCESS,
        color: 'text-color-success'
    },
    /* {
         key: 'failed',
         label: <FormattedMessage id={'intl-msg:deviceUpdateOutcome.failed'}/>,
         outcome: UpdateHistorySearchCriteriaOutcomeEnum.FAILED,
         color: 'text-color-danger'
     },*/
];

const UpdatesEventsOverview: React.FC = () => {
    const [fetchUpdatesCount] = useLazyFetchUpdatesCountQuery();
    const [updateEvents, setUpdateEvents] = useState({
        success: undefined,
        failure: undefined
    });
    const [timeframeSelected, setTimeFrameSelected] = useState<string>('all');

    useEffect(() => {
        const getUpdatedCount = async() => {
            const timeFrame = timeframes[timeframeSelected] && {
                from: timeframes[timeframeSelected],
            };
            setUpdateEvents({
                success: undefined,
                failure: undefined
            });
            let updatedCounts = { ...updateEvents };
            await Promise.all(
                updateEventsTypes.map(async({ key, outcome }) => {
                    const result = await fetchUpdatesCount({ outcome, ...timeFrame }, true);
                    updatedCounts[key] = result?.data || 0;
                })
            );
            setUpdateEvents(updatedCounts);
        };
        getUpdatedCount().catch(console.error);
    }, [timeframeSelected]);

    const renderValue = (key, number, color) => {
        const hasTimeFrame = timeframeSelected !== 'all' ? `&from=${timeframes[timeframeSelected].getTime()}&to=${new Date().getTime()}` : '';
        if (!number) {
            return <div className={'max-width-100'}>
                <ContentLoader
                    width={'100%'}/></div>;
        }
        return <Link
            to={`/${OVERVIEW_PATH}/${EVENTS_PATH}?result=${key.toUpperCase()}${hasTimeFrame}`}><span
            className={color}><FormattedNumber
            value={number}/></span></Link>;
    };
    return (
        <>
            <StatsWidgets className=''>
                <StatsWidget style={{ border: 'none' }}>
                    <div className={'padding-top-10 padding-left-10'}>
                        <TagSelector options={timeFrameOptions} selected={timeframeSelected}
                                     onSelect={setTimeFrameSelected}/>
                    </div>
                    <StatsWidget.Header>
                        <span className={'text-size-h5 text-bold'}
                              style={{ fontFamily: 'MANEUROPECONDENSED' }}><FormattedMessage
                            id={'intl-msg:updateEvents'}/></span>
                    </StatsWidget.Header>
                    <StatsWidget.Body>
                        {updateEventsTypes.map(({ key, label, color }, index) => (
                            <React.Fragment key={key}>
                                <StatsWidgetNumber className={`${color}`}
                                                   value={renderValue(key, updateEvents[key], color)} label={label}/>
                                {index !== updateEventsTypes.length - 1 && <StatsWidgetSpacer/>}
                            </React.Fragment>
                        ))}
                    </StatsWidget.Body>
                </StatsWidget>
            </StatsWidgets>
        </>
    );
};

export default UpdatesEventsOverview;
