import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

interface DeviceReplacedModeProps {
    replaced?: boolean | undefined;

}

const DeviceReplacedMode: React.FC<DeviceReplacedModeProps> = ({
    replaced,
}: DeviceReplacedModeProps) => {
    return replaced ? (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={'intl-msg:replaceTooltip'}/>
            </Tooltip>}>
                <span className={classNames('label label-condensed margin-right-5', 'label-warning')}>
                <FormattedMessage id='intl-msg:replaced'/>
            </span>
        </OverlayTrigger>
    ) : null;
};

export default DeviceReplacedMode;
