import React, { useRef } from 'react';

import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_BUNDLES_PATH,
    DELIVERABLES_CM4G_PATH,
    DELIVERABLES_DISTROS_PATH,
    DELIVERABLES_FILES_PATH,
    DELIVERABLES_PATH
} from '~/features/base/constants/routes';

import Chip from '~/features/base/components/Chip';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import classNames from 'classnames';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';
import {
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G
} from '~/features/deliverables/constants/deliverablesParameters';
import { useLazyFetchDeliverableSubtypeQuery } from '~/api/deliverables/DeliverablesManagement.api';
import { SemanticVersion } from '~/api/models';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

interface DeliverableChipProps {
    deliverableId: string;
    bsStyle?: string;
    className?: string;
    deliverableVersion: SemanticVersion;
    deliverableType: string;
    displayValue?: string;
    withoutChip?: boolean;
    deliverableSubtype?: string;
}

export const deliverableSettings = {
    [DeliverableType.APP]: {
        path: DELIVERABLES_APPS_PATH,
        tooltip: 'intl-msg:packageVersion'
    },
    [DeliverableType.DISTRO]: {
        path: DELIVERABLES_DISTROS_PATH,
        tooltip: 'intl-msg:distroVersion'
    },
    [DeliverableType.CM4G]: {
        path: DELIVERABLES_CM4G_PATH,
        tooltip: 'intl-msg:cm4gVersion'
    },
    [DeliverableType.BUNDLE]: {
        path: DELIVERABLES_BUNDLES_PATH,
        tooltip: 'intl-msg:bundleVersion'
    },
    [DeliverableType.FILE]: {
        path: DELIVERABLES_FILES_PATH,
        tooltip: 'intl-msg:fileVersion'
    }
};

export const subtypeMapping = {
    [DELIVERABLE_TYPE_BUNDLE.toUpperCase()]: 'bundleType',
    [DELIVERABLE_TYPE_CM4G.toUpperCase()]: 'cm-4-g-type',
};

export const DeliverableChip = ({
                                    deliverableId,
                                    bsStyle,
                                    className,
                                    deliverableVersion,
                                    deliverableType,
                                    displayValue,
                                    withoutChip,
                                    deliverableSubtype,
                                }: DeliverableChipProps) => {
    const [getDeliverableSubtype] = useLazyFetchDeliverableSubtypeQuery();
    const upperDeliverableType = deliverableType.toUpperCase();

    const version = toShortSemanticVersion(deliverableVersion);

    const cachedSubtype = useRef<string | null>(deliverableSubtype || null);

    const getSubtype = async (path) => {
        try {
            const result = await getDeliverableSubtype({
                deliverableId,
                deliverableType: deliverableType.toUpperCase(),
                deliverableVersion,
            }).unwrap();

            if (result.subtype) {
                cachedSubtype.current = result.subtype;
                path += `&${subtypeMapping[upperDeliverableType]}=${result.subtype}`;
            }

            openInNewTab(path);
            return;
        } catch (error) {
            Notification.error(
                <FormattedMessage
                    id="intl-msg:deliverableSubtype.error"
                    values={{ error: error?.data?.message }}
                />
            );
            console.error("Failed to fetch deliverable subtype:", error);
            return;
        }
    };

    const handleOnClick = async () => {
        let path = `/#${DELIVERABLES_PATH}/${deliverableSettings[upperDeliverableType]?.path}?deliverableIdPrefix=${deliverableId}&deliverableVersion=${version}`;

        if (cachedSubtype.current && subtypeMapping[upperDeliverableType]) {
            path += `&${subtypeMapping[upperDeliverableType]}=${cachedSubtype.current.toUpperCase()}`;
        }

        if (
            (upperDeliverableType === DELIVERABLE_TYPE_BUNDLE.toUpperCase() ||
                upperDeliverableType === DELIVERABLE_TYPE_CM4G.toUpperCase()) &&
            !cachedSubtype.current
        ) {
            await getSubtype(path);
        } else {
            openInNewTab(path);
        }
    };

    return (
        <>
            {!withoutChip ? (
                <Chip
                    onClick={handleOnClick}
                    bsStyle={bsStyle}
                    className={classNames('clickable', className)}
                    toolTipMessage={deliverableSettings[upperDeliverableType]?.tooltip}
                >
                    {displayValue || version}
                </Chip>
            ) : (
                <div onClick={handleOnClick} style={{ cursor: 'pointer' }}>
                    {version}
                </div>
            )}
        </>
    );
};

export default DeliverableChip;
