import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
import {
    DEVICES_WITH_LOGS_PATH,
    EVENTS_PATH,
    HOME_PATH,
    LIST_PATH,
    LOG_REQUESTS_PATH,
} from '~/features/base/constants/routes';

// Overview
import HomeContainer from '~/features/oldOverview/components/HomeContainer';
import UpdateEventsContainer from '~/features/updateEvents/components/UpdateEventsContainer';
import LogRequestsListContainer from '~/features/deviceLogs/components/LogRequestsListContainer';
import LogRequestDetailsContainer from '~/features/deviceLogs/components/LogRequestDetailsContainer';
import DevicesWithLogsListContainer from '~/features/deviceLogs/components/DevicesWithLogsListContainer';
import { sendGoogleAnalyticsEvent } from '~/index';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';

export const LogRequestDetailsRoute = ({ match }) => {
    const serialNumber = match.params.serialNumber;
    return (
        <LogRequestDetailsContainer serialNumber={serialNumber}/>
    );
};

export const LogRequestsRoute = ({ match }) => {
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Overview Page', 'View Open Log Requests');
    return (
        <Switch>
            <Route path={`${match.path}/${LIST_PATH}`}
                   component={LogRequestsListContainer}/>
            <Route path={`${match.path}/:serialNumber`} component={LogRequestDetailsRoute}/>
            <Redirect to={`${match.url}/${LIST_PATH}`}/>
        </Switch>
    );
};

export const DevicesWithLogsRoute = ({ match }) => {
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Overview Page', 'View Devices With Logs');
    return (
        <Switch>
            <Route path={`${match.path}/${LIST_PATH}`}
                   component={DevicesWithLogsListContainer}/>
            <Redirect to={`${match.url}/${LIST_PATH}`}/>
        </Switch>
    );
};

export const OverviewRoutes = ({ match }) => {

    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Overview Page', 'View Overview');
    useDataInterestCleaner();

    return (
        <Switch>
            <Route path={`${match.path}/${HOME_PATH}`} component={(props) => <HomeContainer {...props}/>}/>
            <Route path={`${match.path}/${EVENTS_PATH}`} component={(props) => <UpdateEventsContainer {...props}/>}/>
            <Route path={`${match.path}/${LOG_REQUESTS_PATH}`} component={(props) => <LogRequestsRoute {...props}/>}/>
            <Route path={`${match.path}/${DEVICES_WITH_LOGS_PATH}`}
                   component={props => <DevicesWithLogsRoute {...props}/>}/>
            <Redirect to={`${match.url}/${HOME_PATH}`}/>
        </Switch>
    );
};

export default OverviewRoutes;
