import React from 'react';
import OverviewFooter from '~/features/overview/components/OverviewFooter';
import OverviewAllVehiclesInfo from '~/features/overview/components/OverviewAllVehiclesInfo';
import OverviewDataSummary from '~/features/overview/components/OverviewDataSummary';

interface OverviewContainerProps {

}

const OverviewContainer: React.FC<OverviewContainerProps> = () => {

    return (
        <div className="overview">
            <OverviewDataSummary/>
            <OverviewAllVehiclesInfo/>
            <OverviewFooter/>
        </div>
    );
};

export default OverviewContainer;
