import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import React, {ReactNode} from 'react';

interface TagOption {
    key: string;
    label: string | ReactNode;
}

interface TagSelectorProps {
    options: TagOption[];
    selected: string;
    onSelect: (key: string) => void;
}

const TagSelector = ({ options, selected, onSelect }: TagSelectorProps) => {
    return (
        <TagList className='margin-top-10'>
            {options.map(({ key, label }) => (
                <Tag
                    size={'small'}
                    key={String(key)}
                    clickable
                    active={selected === key}
                    onClick={() => onSelect(key)}
                >
                    {label}
                </Tag>
            ))}
        </TagList>
    );
};

export default TagSelector;
