import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { hotkeys } from 'react-keyboard-shortcuts';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import uid from '~/features/base/utils/uid';
import { DeviceListSelectionDialog } from '~/features/devices/components/DeviceListSelectionDialog';

export class DeviceListToolbar extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showDevicesSelectionDialog: false,
        };
    }
    onSearchValueChange = (prefixValue) => {
        this.props.onSearchValueChange(prefixValue);
    };

    onShowFilterDialog = () => {
        this.props.onShowFilterDialog();
    };

    onLastCheckForUpdateChange = (value) => {
        this.props.onLastCheckForUpdateChange(value);
    };

    onShowControlDeviceEditor = () => {
        this.props.onShowControlDeviceEditor();
    };

    onShowColumnSettings = () => {
        this.props.onShowColumnSettings();
    };

    // eslint-disable-next-line camelcase
    hot_keys = {
        'alt+f': {
            priority: 1,
            handler: (event) => this.props.onShowFilterDialog(),
        },
    };

    onClearFiltersClicked = () => {
        this.props.clearFilter();
    };

    render() {
        const { children, defaultedQuery } = this.props;
        const filterDialogButton = this.renderFilterDialogButton();
        const lastCheckForUpdateAfterDatePicker = this.renderLastCheckForUpdateAfterDatePicker(defaultedQuery);
        const searchField = this.renderSearchField(defaultedQuery);
        const columnSettingsButton = this.renderColumnSettingsButton();
        const exportDropdown = this.renderActionsDropdown();
        return (
            <div className='row device-list-toolbar'>
                <div className='col-4'>
                    <div className='btn-toolbar'>
                        {children}
                    </div>
                </div>
                <div className='col-8'>
                    <div className='btn-toolbar pull-right'>
                        {filterDialogButton}
                        {searchField}
                        {lastCheckForUpdateAfterDatePicker}
                        {exportDropdown}
                        {columnSettingsButton}
                    </div>
                </div>
            </div>
        );
    }

    renderSearchField(defaultedQuery) {
        const { intl } = this.props;
        const searchFieldLabel = intl.formatMessage({ id: 'intl-msg:findSerialOrVin' });
        return <div className='table-toolbar-column width-200'>
            <TableSearch
                value={defaultedQuery.serialNumberPrefix || defaultedQuery.vinPrefix}
                onChange={this.onSearchValueChange}
                placeholder={searchFieldLabel}
            />
        </div>;
    }

    renderFilterDialogButton() {
        return <div className='justify-content-center align-items-center'>
            <div className='text-center'>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        <FormattedMessage id='intl-msg:deviceFilterTooltip'/>
                    </Tooltip>}>
                    <button className='btn btn-default btn-icon-only'
                            onClick={this.onShowFilterDialog} role='button'>
                        <span className='rioglyph rioglyph-filter'></span>
                    </button>
                </OverlayTrigger>
            </div>
            <div className='text-center'>
                <button type='button' className='btn btn-primary btn-link' onClick={() => this.onClearFiltersClicked()}>
                    <b><FormattedMessage className='' id='intl-msg:reset'/></b>
                </button>
            </div>
        </div>;
    }

    renderLastCheckForUpdateAfterDatePicker(defaultedQuery) {
        return <div className=' table-toolbar-column width-200'>
            <DatePicker
                inputProps={{ placeholder: 'Last seen after' }}
                value={defaultedQuery.lastCheckForUpdateAfter ? moment(
                    parseInt(defaultedQuery.lastCheckForUpdateAfter, 10)) : null}
                onChange={this.onLastCheckForUpdateChange}
            />
        </div>;
    }

    renderColumnSettingsButton() {
        return <div className='table-toolbar-column'>
            <button className='btn btn-default btn-icon-only'
                    onClick={this.onShowColumnSettings} role='button'>
                <span className='rioglyph rioglyph-cog'></span>
            </button>
        </div>;
    }

    onShowDevicesSelectionDialog = () => {
        this.setState({ showDevicesSelectionDialog: true });
    };

    onHideDevicesSelectionDialog = () => {
        this.setState({ showDevicesSelectionDialog: false });
    };

    renderActionsDropdown() {
        const items = [];
        items.push(
            {
                value: [
                    <span key={uid()} className='rioglyph rioglyph-cards-grid padding-right-5'
                          aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportCSV'/>,
                ],
                onSelect: () => this.props.onExportDevices('csv'),
            },
        );
        items.push(
            {
                value: [
                    <span key={uid()} className='rioglyph rioglyph-cards-list padding-right-5'
                          aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportSerials'/>,
                ],
                onSelect: () => this.props.onExportDevices('serials'),
            },
        );
        items.push(
            {
                value: [
                    <span key={uid()} className='rioglyph rioglyph rioglyph-area-out padding-right-5'
                          aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:selectDevices'/>,
                ],
                onSelect: this.onShowDevicesSelectionDialog,
                disabled: !this.props.canCreateControlDevices,
            },
        );
        items.push(
            {
                value: [<hr className='margin-0'></hr>],
                disabled: true,
            },
        );
        items.push(
            {
                value: [
                    <span key={uid()} className='rioglyph rioglyph-plus padding-right-5' aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:createControlDevice'/>,
                ],
                onSelect: this.onShowControlDeviceEditor,
                disabled: !this.props.canCreateControlDevices,
            },
        );

        return (
            size(items) ? (
                <div className='table-toolbar-column'>
                    <ButtonDropdown
                        id='mySimpleButtonDropdown'
                        title={
                            <span>
                                <FormattedMessage id='intl-msg:moreActions'/>
                        </span>
                        }
                        items={items}
                    />
                    {this.state.showDevicesSelectionDialog && (
                        <DeviceListSelectionDialog
                            onHide={this.onHideDevicesSelectionDialog}
                        />
                    )}
                </div>
            ) : null
        );
    }
}

export default injectIntl(hotkeys(DeviceListToolbar));

DeviceListToolbar.defaultProps = {
    // props
    canCreateControlDevices: false,
    defaultedQuery: {},
    // functions
    onShowControlDeviceEditor: noop,
    onShowColumnSettings: noop,
    onLastCheckForUpdateChange: noop,
    onSearchValueChange: noop,
    onShowFilterDialog: noop,
    updateFilter: noop,
    clearFilter: noop,
};

DeviceListToolbar.propTypes = {
    // props
    canCreateControlDevices: PropTypes.bool,
    defaultedQuery: PropTypes.object,
    // functions
    onShowControlDeviceEditor: PropTypes.func,
    onShowColumnSettings: PropTypes.func,
    onLastCheckForUpdateChange: PropTypes.func,
    onSearchValueChange: PropTypes.func,
    onShowFilterDialog: PropTypes.func,
    updateFilter: PropTypes.func,
    clearFilter: PropTypes.func,
};
