import PropTypes from 'prop-types';
import getOr from 'lodash/fp/getOr';
import React from 'react';
import { Link } from 'react-router-dom';

import Tile from '~/features/oldOverview/components/Tile';
import { stringifyToQuery } from '~/features/base/utils/query';
import { FormattedNumber } from 'react-intl';
import includes from 'lodash/fp/includes';
import uid from '~/features/base/utils/uid';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

export const MultipleNumbersTile = ({ parentProps, label, tileDescriptor }) => {
    return (
        <Tile>
            <div data-name={name} className='cursor-pointer bg-white bg-tile height-500'>
                <div className='display-flex flex-column justify-content-center align-items-center height-100pct'>
                    {
                        tileDescriptor.values.map(value => {
                            if (!value.permission || !includes(value.permission, parentProps.permissions)) {
                                return;
                            }
                            const numericalValue = getOr(0, value.dataPath, parentProps);
                            return (
                                <Link key={uid()} className='min-width-400 display-flex'
                                      to={`${value.link}?${stringifyToQuery(value.query)}`}>
                                    <span
                                        className='flex-0-0 text-size-20 text-bold text-color-darkest'>{value.label}</span>
                                    <span
                                        className='flex-1-1 text-size-20 text-bold text-color-darkest text-right'>
                                        <FormattedNumber value={numericalValue}/>
                                    </span>
                                </Link>
                            );
                        })
                    }
                </div>
                <div className='position-relative'>
                    <div className={labelWrapperClasses}>
                        <div>
                            <span className={labelClasses}>{label}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Tile>
    );
};

export default MultipleNumbersTile;

MultipleNumbersTile.propTypes = {
    // props
    label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    tileDescriptor: PropTypes.object,
};
