import map from 'lodash/fp/map';
import keys from 'lodash/fp/keys';

import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import {
    PERMISSION_CONTROL_DEVICE_READ,
    PERMISSION_DEVICE_ACTION_READ,
    PERMISSION_PACKAGE_DELIVERABLE_READ,
    PERMISSION_PACKAGE_READ,
    PERMISSION_SOFTWARE_INFO_READ,
    PERMISSION_UPDATE_HISTORY_READ,
} from '~/features/base/constants/permissions';

import { FETCH_HOME_DATA } from '~/features/oldOverview/actions/homeActions';
import {
    FETCH_PACKAGE_COUNT,
    fetchPackageCount,
} from '~/features/deliverables/features/packages/actions/packageActions';
import {
    FETCH_PACKAGE_DELIVERABLE_COUNT,
    fetchPackageDeliverableCount,
} from '~/features/deliverables/features/packages/actions/packageDeliverableActions';
import { FETCH_DEVICE_ACTION_COUNT, fetchDeviceActionCount } from '~/features/deviceLogs/actions/deviceActionActions';

import {
    FETCH_BLOCKED_DEVICE_COUNT,
    FETCH_CONTROL_DEVICE_COUNT,
    FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW,
    FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET,
    FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO,
    FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET,
    FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT,
    FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET,
    FETCH_ERROR_DEVICE_COUNT,
    FETCH_IN_CUSTOMER_FLEET_COUNT,
    FETCH_TEST_DEVICE_COUNT,
    FETCH_WARNING_DEVICE_COUNT,
    fetchBlockedDeviceCount,
    fetchControlDeviceCount,
    fetchControlDeviceCountsByBaseSw,
    fetchControlDeviceCountsByBaseSwInCustomerFleet,
    fetchControlDeviceCountsByDistro,
    fetchControlDeviceCountsByDistroInCustomerFleet,
    fetchControlDeviceCountsByHwVariant,
    fetchControlDeviceCountsByHwVariantInCustomerFleet,
    fetchErrorDeviceCount,
    fetchInCustomerFleetCount,
    fetchTestDeviceCount,
    fetchWarningDeviceCount,
} from '~/features/devices/actions/controlDeviceActions';
import { FETCH_UPDATE_EVENT_COUNT, fetchUpdateEventCount } from '~/features/updateEvents/actions/updateEventActions';

import { FETCH_USER, fetchUser } from '~/features/user/actions/userActions';

import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';

import { finishedAction, toFinished } from '~/features/higherorder/transforms/actionTransforms';
import { getTimestampSixMonthsAgo } from '~/features/base/constants/filterOptions';
import {
    FETCH_DEVICE_COUNTS_BY_COUNTRIES,
    FETCH_DEVICE_COUNTS_BY_VEHICLES,
    fetchDeviceCountsByCountries,
    fetchDeviceCountsByVehicles,
} from '~/features/devices/actions/ui/deviceDistributionsActions';
import { FETCH_LIVE_DELIVERABLES, fetchLiveDeliverables } from '~/features/deliverables/actions/deliverableActions';
import {
    FETCH_DISTRO_COUNTS_BY_RELEASE_STATE,
    fetchDistroCountsByReleaseState,
} from '~/features/deliverables/features/distros/actions/distroActions';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
} from '~/features/deliverables/constants/deliverablesParameters';

const homeData = {
    fetchLiveDistros: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchLiveDeliverables,
        finishedAction: toFinished(FETCH_LIVE_DELIVERABLES),
        options: {
            type: DELIVERABLE_TYPE_DISTRO.toUpperCase(),
        },
    },
    fetchLiveApps: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchLiveDeliverables,
        finishedAction: toFinished(FETCH_LIVE_DELIVERABLES),
        options: {
            type: DELIVERABLE_TYPE_APP.toUpperCase(),
        },
    },
    fetchLiveCM4G: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchLiveDeliverables,
        finishedAction: toFinished(FETCH_LIVE_DELIVERABLES),
        options: {
            type: DELIVERABLE_TYPE_CM4G.toUpperCase(),
        },
    },
    fetchLiveBundles: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchLiveDeliverables,
        finishedAction: toFinished(FETCH_LIVE_DELIVERABLES),
        options: {
            type: DELIVERABLE_TYPE_BUNDLE.toUpperCase(),
        },
    },
    fetchControlDeviceCount: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCount,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNT),
        options: {
            searchCriteria: {
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchInCustomerFleetCount: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchInCustomerFleetCount,
        finishedAction: toFinished(FETCH_IN_CUSTOMER_FLEET_COUNT),
        options: {
            searchCriteria: {
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchControlDeviceCountsByBaseSw: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCountsByBaseSw,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW),
        options: {
            searchCriteria: {
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchControlDeviceCountsByDistro: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCountsByDistro,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO),
        options: {
            searchCriteria: {
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchControlDeviceCountsByHwVariant: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCountsByHwVariant,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT),
        options: {
            searchCriteria: {
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchControlDeviceCountsByBaseSwInCustomerFleet: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCountsByBaseSwInCustomerFleet,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET),
        options: {
            searchCriteria: {
                inCustomerFleet: true,
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchControlDeviceCountsByDistroInCustomerFleet: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCountsByDistroInCustomerFleet,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET),
        options: {
            searchCriteria: {
                inCustomerFleet: true,
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchControlDeviceCountsByHwVariantInCustomerFleet: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchControlDeviceCountsByHwVariantInCustomerFleet,
        finishedAction: toFinished(FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET),
        options: {
            searchCriteria: {
                inCustomerFleet: true,
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
            },
        },
    },
    fetchTestDeviceCount: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchTestDeviceCount,
        finishedAction: toFinished(FETCH_TEST_DEVICE_COUNT),
        options: {},
    },
    fetchBlockedDeviceCount: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchBlockedDeviceCount,
        finishedAction: toFinished(FETCH_BLOCKED_DEVICE_COUNT),
        options: {},
    },
    fetchWarningDeviceCount: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchWarningDeviceCount,
        finishedAction: toFinished(FETCH_WARNING_DEVICE_COUNT),
        options: {},
    },
    fetchErrorDeviceCount: {
        permission: PERMISSION_CONTROL_DEVICE_READ,
        action: fetchErrorDeviceCount,
        finishedAction: toFinished(FETCH_ERROR_DEVICE_COUNT),
        options: {},
    },
    fetchPackageCount: {
        permission: PERMISSION_PACKAGE_READ,
        action: fetchPackageCount,
        finishedAction: toFinished(FETCH_PACKAGE_COUNT),
    },
    fetchPackageDeliverableCount: {
        permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
        action: fetchPackageDeliverableCount,
        finishedAction: toFinished(FETCH_PACKAGE_DELIVERABLE_COUNT),
    },
    fetchUpdateEventCount: {
        permission: PERMISSION_UPDATE_HISTORY_READ,
        action: fetchUpdateEventCount,
        finishedAction: toFinished(FETCH_UPDATE_EVENT_COUNT),
        options: {
            // scope: 'totalCount',
        },
    },
    fetchDistroCountsByReleaseState: {
        permission: PERMISSION_SOFTWARE_INFO_READ,
        action: fetchDistroCountsByReleaseState,
        finishedAction: toFinished(FETCH_DISTRO_COUNTS_BY_RELEASE_STATE),
        options: {},
    },
    fetchDeviceActionCount: {
        permission: PERMISSION_DEVICE_ACTION_READ,
        action: fetchDeviceActionCount,
        finishedAction: toFinished(FETCH_DEVICE_ACTION_COUNT),
        options: {},
    },
    fetchDeviceCountsByCountries: {
        permission: PERMISSION_DEVICE_ACTION_READ,
        action: fetchDeviceCountsByCountries,
        finishedAction: toFinished(FETCH_DEVICE_COUNTS_BY_COUNTRIES),
        options: {},
    },
    fetchDeviceCountsByVehicles: {
        permission: PERMISSION_DEVICE_ACTION_READ,
        action: fetchDeviceCountsByVehicles,
        finishedAction: toFinished(FETCH_DEVICE_COUNTS_BY_VEHICLES),
        options: {
            searchCriteria: {
                lastCheckForUpdateAfter: getTimestampSixMonthsAgo(),
                deviceTypes: ['TBM3'],
            },
        },
    },
};

export function* doMakeFetchSaga(toLoadItem) {
    const permissions = yield select(permissionsSelector);
    const toLoadData = homeData[toLoadItem];
    if (!toLoadData) {
        return;
    }
    if (!toLoadData.permission || permissions.includes(toLoadData.permission)) {
        yield put(toLoadData.action(toLoadData.options));
        yield take(toLoadData.finishedAction);
    }
}

export function* doMakeFetchesSaga(toLoad, parallel = true) {
    const calls = map(toLoadItem => {
        if (Array.isArray(toLoadItem)) {
            return call(doMakeFetchesSaga, toLoadItem, true);
        }
        return call(doMakeFetchSaga, toLoadItem);
    }, toLoad);

    let result;
    if (parallel) {
        result = yield all(calls);
    } else {
        result = [];
        for (const c of calls) {
            result.push(yield c);
        }
    }
    return result;
}

export function* doFetchHomeDataSaga() {
    yield put(fetchUser());
    yield take(toFinished(FETCH_USER));
    yield call(doMakeFetchesSaga, keys(homeData), true);
    yield put(finishedAction(FETCH_HOME_DATA));
}

export function* fetchHomeDataSaga() {
    yield takeLatest(FETCH_HOME_DATA, doFetchHomeDataSaga);
}
