import React, { useEffect, useState } from 'react';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';
import StatsWidget from '@rio-cloud/rio-uikit/lib/es/StatsWidget';
import StatsWidgets from '@rio-cloud/rio-uikit/lib/es/StatsWidgets';
import { useFetchActiveRolloutsQuery, } from '~/api/deliverables/DeliverablesManagement.api';
import { RolloutOverviewItem } from '~/api/models/rollout-overview-item';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { FormattedMessage } from 'react-intl';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import DeliverableChip, {
    deliverableSettings,
    subtypeMapping
} from '~/features/deliverables/components/DeliverableChip';
import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { DeviceTypeLabel } from '~/features/devices/components/DeviceType';
import { CM4, TBM3 } from '~/features/devices/constants/deviceTypes';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import { DELIVERABLES_PATH } from '~/features/base/constants/routes';

export const deliverablesTypes = [DeliverableType.BUNDLE, DeliverableType.CM4G, DeliverableType.APP, DeliverableType.DISTRO, DeliverableType.FILE];
const ALL = 'All';
const itemAll = {
    id: ALL,
    label: <FormattedMessage id={'intl-msg:overview.rollout.allDeliverablesTypeFilter'}/>
};

const OverviewRolloutsTile: React.FC = () => {

    const deliverablesOptions = [...deliverablesTypes.map(d => ({
        id: d,
        label: d
    })), itemAll];

    const { data: allRollouts, isLoading, error } = useFetchActiveRolloutsQuery({});
    const [filteredRollouts, setFilteredRollouts] = useState<RolloutOverviewItem[] | undefined>(allRollouts);
    const [rolloutsFilter, setRolloutsFilter] = useState(itemAll);

    useEffect(() => {
        let updatedRollouts = allRollouts;
        if (rolloutsFilter.id !== ALL) {
            updatedRollouts = updatedRollouts?.filter((rollout) => rollout.deliverableType === rolloutsFilter.id);
        }
        setFilteredRollouts(updatedRollouts);
    }, [allRollouts, rolloutsFilter]);

    const getDeviceTag = (key: string): React.ReactNode => {
        switch (key) {
            case  DeliverableType.APP:
            case DeliverableType.DISTRO:
                return <DeviceTypeLabel deviceType={TBM3}/>;
            case DeliverableType.CM4G:
            case DeliverableType.BUNDLE:
                return <DeviceTypeLabel deviceType={CM4}/>;

            default:
                return undefined;
        }
    };
    const handleFilterOnChange = (item) => {
        setRolloutsFilter(item);
    };
    const emptyStateMsg = rolloutsFilter.id !== ALL ?
        <FormattedMessage id='intl-msg:overview.rollout.emptyStateMessage'
                          values={{
                              deliverableType: rolloutsFilter.id,
                          }}/> : <FormattedMessage id='intl-msg:overview.rollout.noActiveRollouts'/>;

    const emptyState = <NotFoundState className='height-100pct align-items-center display-flex'
                                      outerClassName='border-none' headline='Nothing found'
                                      message={emptyStateMsg}/>;

    const contentLoader = <div className={'display-flex  flex-column gap-10'}>
        <ContentLoader width='100%'/>
        <ContentLoader width='20%'/>
        <ContentLoader width='20%'/>
        <ContentLoader width='70%'/>
    </div>

    const handleOnRolloutClick = (deliverableType, deliverableId, deliverableVersion, cachedSubtype,) => {
        const subtypePath = cachedSubtype ? `&${subtypeMapping[deliverableType]}=${cachedSubtype.toUpperCase()}` : '';
        const path = `/#${DELIVERABLES_PATH}/${deliverableSettings[deliverableType]?.path}?deliverableIdPrefix=${deliverableId}&deliverableVersion=${deliverableVersion}${subtypePath}`;
        openInNewTab(path);

    };

    return (
        <div className='rollout-tile '>
            <div className=' text-start text-size-h5 text-bold'
                 style={{ fontFamily: 'MANEUROPECONDENSED' }}><FormattedMessage id='intl-msg:rollouts'/></div>
            <div className='row margin-top-20 margin-bottom-20'>
                <div className={'col-3'}>
                    <Select placeholder='Please select' value={[rolloutsFilter.id]} options={deliverablesOptions}
                            onChange={handleFilterOnChange}/>
                </div>
            </div>

            <div className='row' style={{ overflowY: 'auto', height: 500 }}>
                <StatsWidgets className={'gap-0'}>
                    {((filteredRollouts && filteredRollouts.length === 0) || !!error) &&
                        <div className='display-flex justify-content-center align-items-center width-100pct'>
                            {emptyState}
                        </div>
                    }
                    {
                        isLoading && Array(6).fill(0, 0, 6).map((_) =>
                            <div className={'col-4 margin-bottom-20'}>

                                <StatsWidget>
                                    <StatsWidget.Body className={'display-flex flex-column align-items-start '}>
                                        {contentLoader}
                                    </StatsWidget.Body>
                                </StatsWidget>
                            </div>
                        )
                    }
                    {filteredRollouts?.map((r) => (
                        <div className={'col-4 margin-bottom-20'} style={{ cursor: 'pointer' }}>
                            <StatsWidget

                                onClick={() => handleOnRolloutClick(r.deliverableType, r.deliverableId, r.deliverableVersion, r.deliverableSubtype)
                                }>
                                <StatsWidget.Body className={'display-flex flex-column align-items-start '}>
                                    <div className={'text-size-16'}
                                         style={{ fontFamily: 'MANEUROPECONDENSED' }}> {r.deliverableId}</div>
                                    {getDeviceTag(r.deliverableType)}
                                    <div className={'text-size-14'}
                                         style={{ fontFamily: 'MANEUROPECONDENSED' }}><DeliverableChip withoutChip
                                                                                                       deliverableId={r.deliverableId}
                                                                                                       deliverableType={r.deliverableType}
                                                                                                       deliverableVersion={toSemanticVersion(r.deliverableVersion)}/>
                                    </div>
                                    <div className={'display-flex flex-row align-items-baseline width-100pct '}>
                                        <div className={'width-100pct'}>
                                            <div className='progress '>
                                                <div className='progress-bar progress-bar-success'
                                                     style={{ width: `${r.successfulRolloutsPercentage}%` }}/>
                                            </div>
                                        </div>
                                        <div className=' margin-left-5 text-size-14'>
                                            {`${r.successfulRolloutsPercentage}%`}
                                        </div>
                                    </div>
                                </StatsWidget.Body>
                            </StatsWidget>
                        </div>
                    ))
                    }
                </StatsWidgets>
            </div>


        </div>
    );
};

export default OverviewRolloutsTile;
