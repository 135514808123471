import { USER } from '~/features/groups/constants/groupTypes';

import { CHANGE_GROUP_EDITOR, RESET_GROUP_EDITOR } from '~/features/groups/actions/groupEditorActions';
import { CREATE_GROUP, UPDATE_GROUP } from '~/features/groups/actions/groupActions';

import { toFinished } from '~/features/higherorder/transforms/actionTransforms';

const defaultState = {
    isNew: true,
    name: '',
    type: USER,
    description: '',
    autoGroupCriteria: {
        replaced: false,
    },
    serialNumbers: [],
    isValidSerialNumbers: true,
};

export const resetGroupEditor = (state = defaultState) => {
    return state;
};
export const changeGroupEditor = (state, { payload }) => {
    const newState = {
        ...state,
        ...payload,
    };
    return newState;
};
export const setInProgress = (state) => {
    return {
        ...state,
        isInProgress: true,
    };
};
export const setNotInProgress = (state) => {
    return {
        ...state,
        isInProgress: false,
    };
};

export default function groupEditorReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case RESET_GROUP_EDITOR:
            return resetGroupEditor();
        case CHANGE_GROUP_EDITOR:
            return changeGroupEditor(state, action);
        case CREATE_GROUP:
        case UPDATE_GROUP:
            return setInProgress(state);
        case toFinished(CREATE_GROUP):
        case toFinished(UPDATE_GROUP):
            return setNotInProgress(state);
        default:
            return state;
    }
}
