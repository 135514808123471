import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { tileDescriptors } from '~/features/oldOverview/components/HomeContainer';

import NumberTile from '~/features/oldOverview/components/NumberTile';
import DonutTile from '~/features/oldOverview/components/DonutTile';
import PageHeader from '~/features/base/components/PageHeader';
import MultipleNumbersTile from '~/features/oldOverview/components/MultipleNumbersTile';
import QuarterNumberTile from '~/features/oldOverview/components/QuarterNumberTile';
import CarouselTile from '~/features/oldOverview/components/CarouselTile';
import DistributionTile from '~/features/oldOverview/components/DistributionTile';

/**
 * Component for the device management home
 */
export class Home extends PureComponent {
    onNumberTileClick = (tileDescriptor) => {
        this.props.onTileClick(tileDescriptor);
    };

    onDonutTileClick = (tileDescriptor, distroVersion) => {
        this.props.onTileClick(tileDescriptor, {
            [tileDescriptor.searchPath]: distroVersion,
        });
    };

    renderTile = (tileDescriptor) => {
        if (!tileDescriptor.permission || includes(tileDescriptor.permission, this.props.permissions)) {
            if (tileDescriptor.type === 'numberTile') {
                const value = getOr(0, tileDescriptor.dataPath, this.props);
                return (
                    <NumberTile key={tileDescriptor.id}
                                label={tileDescriptor.label}
                                value={value}
                                tileDescriptor={tileDescriptor}/>
                );
            } else if (tileDescriptor.type === 'multipleNumberTile') {
                return (
                    <MultipleNumbersTile key={tileDescriptor.id} parentProps={this.props}
                                         label={tileDescriptor.label}
                                         tileDescriptor={tileDescriptor}/>
                );
            } else if (tileDescriptor.type === 'donutTile') {
                const data = getOr(0, tileDescriptor.dataPath, this.props);
                return (
                    <DonutTile key={tileDescriptor.id}
                               label={tileDescriptor.label}
                               data={data}
                               tileDescriptor={tileDescriptor}
                               onClick={this.onDonutTileClick}/>
                );
            } else if (tileDescriptor.type === 'quarterNumberTile') {
                return (
                    <QuarterNumberTile key={tileDescriptor.id} values={tileDescriptor.values}
                                       props={this.props}
                    />
                );
            } else if (tileDescriptor.type === 'carouselTile') {
                return (
                    <CarouselTile key={tileDescriptor.id} className='height-500 position-relative'
                                  values={tileDescriptor.values}
                                  props={this.props}
                                  onDonutTileClick={this.onDonutTileClick}
                    />
                );
            } else if (tileDescriptor.type === 'distributionTile') {
                return (
                    <DistributionTile key={tileDescriptor.id} props={this.props}
                                      tileDescriptor={tileDescriptor}
                    />

                );
            }
        }
    };

    render() {
        const { permissions } = this.props;
        if (!size(permissions)) {
            return <FormattedMessage id='intl-msg:accessNotAllowed'/>;
        }

        const tiles = this.renderTiles();
        return (
            <div className='home'>
                <div className='home-header row'>
                    <PageHeader pageTitle={<FormattedMessage id='intl-msg:overview'/>}/>
                    <button type='button'
                            className='position-relative bottom-25 padding-left-10 padding-right-2 btn btn-info btn-link'>
                        <span className='rioglyph rioglyph-warning-sign' aria-hidden='true'></span>
                        <FormattedMessage id='intl-msg:overview.warning.dataScope.lastSixMonths'/>
                    </button>
                </div>
                <div className='home-content home-tiles row equal-height'>
                    {tiles}
                </div>
            </div>
        );
    }

    renderTiles() {
        return map(this.renderTile, tileDescriptors);
    }
}

export default Home;

Home.defaultProps = {
    // props
    permissions: [],
    tileDescriptors: [],
    deviceActions: [],
    distros: {},
    // functions
    onTileClick: noop,
};

Home.propTypes = {
    // props
    permissions: PropTypes.array,
    tileDescriptors: PropTypes.array,
    deviceActions: PropTypes.array,
    distros: PropTypes.object,
    // functions
    onTileClick: PropTypes.func,
};
