import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import Tile from '~/features/oldOverview/components/Tile';
import QuarterTile from '~/features/oldOverview/components/QuarterTile';
import getOr from 'lodash/fp/getOr';
import { Link } from 'react-router-dom';
import uid from '~/features/base/utils/uid';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

export const QuarterNumberTile = (values) => {
    const tiles = values.values.map(tileDescriptor => {
        const value = getOr(0, tileDescriptor.dataPath, values.props);
        return (
            <QuarterTile key={uid()}>
                <Link to={`${tileDescriptor.link}`}>
                    <div data-name={tileDescriptor.id} className='cursor-pointer bg-white bg-tile height-250'>
                        <div className='display-flex justify-content-center align-items-center height-100pct'>
                        <span className='text-size-18 text-bold text-color-darkest'>
                            <span className='text-size-200pct'><FormattedNumber value={value}/></span>
                        </span>
                        </div>
                        <div className='position-relative'>
                            <div className={labelWrapperClasses}>
                                <span className={labelClasses}>{tileDescriptor.label}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            </QuarterTile>
        );
    });
    return (
        <Tile>
            {tiles}
        </Tile>
    );
};

export default QuarterNumberTile;

QuarterNumberTile.propTypes = {
    values: PropTypes.array,
};
