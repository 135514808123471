import React, { useEffect, useState } from 'react';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/StatsWidgetNumber';
import { useLazyFetchDevicesCountQuery } from '~/api/controlDevice/ControlDevice.api';
import { ControlDeviceSearchCriteriaTypeEnum } from '~/api/models';
import StatsWidgetSpacer from '@rio-cloud/rio-uikit/StatsWidgetSpacer';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import TagSelector from '~/features/base/components/TagSelector';
import { DEVICES_PATH, LIST_PATH } from '~/features/base/constants/routes';
import { Link } from 'react-router-dom';

const deviceTypes = [
    { key: 'cm4', label: 'CM4', type: ControlDeviceSearchCriteriaTypeEnum.CM4 },
    { key: 'tbm3', label: 'TBM3', type: ControlDeviceSearchCriteriaTypeEnum.TBM3 },
    { key: 'vcm', label: 'VCM', type: ControlDeviceSearchCriteriaTypeEnum.VCM }
];
const tagOptions = [
    { key: 'all', label: 'All' },
    { key: 'activated', label: 'Activated' },
];

const OverviewDevicesTypes: React.FC = () => {
    const [tagSelected, setTagSelected] = useState<string>('all');
    const [getDevicesCount] = useLazyFetchDevicesCountQuery();
    const [deviceCounts, setDeviceCounts] = useState<{ cm4?: Number; tbm3?: Number; vcm?: Number }>({
        cm4: undefined,
        tbm3: undefined,
        vcm: undefined,
    });

    useEffect(() => {
        const fetchDeviceCounts = async() => {
            const inCustomerFleet = tagSelected === 'activated' || undefined;
            setDeviceCounts({
                cm4: 0,
                tbm3: 0,
                vcm: 0,
            });
            let updatedCounts = { ...deviceCounts };
            await Promise.all(
                deviceTypes.map(async({ key, type }) => {
                    const result = await getDevicesCount({ type, inCustomerFleet }, true);
                    updatedCounts[key] = result?.data || 0;
                })
            );
            setDeviceCounts(updatedCounts);
        };
        fetchDeviceCounts().catch(console.error);
    }, [tagSelected, getDevicesCount]);

    const renderValue = (key, number) => {
        const hasActivatedDevices = tagSelected === 'activated' ? `&in-customer-fleet=true` : ' ';
        if (number === undefined) {
            return <div className={'max-width-100'}>
                <ContentLoader
                    width={'100%'}/></div>;
        }
        return <Link
            to={`/${DEVICES_PATH}/${LIST_PATH}?device-type=${key.toUpperCase()}${hasActivatedDevices}`}>
            <span className={'text-color-status-driving'}>
            <FormattedNumber value={number}/></span></Link>;

    };
    return (
        <StatsWidgets>
            <StatsWidget style={{ border: 'none' }}>
                <div className={'padding-top-10 padding-left-10'}>
                    <TagSelector options={tagOptions} onSelect={setTagSelected} selected={tagSelected}/>
                </div>
                <StatsWidget.Header>
                    <span className={'text-size-h5 text-bold'} style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                        <FormattedMessage id={'intl-msg:overview:deviceTypesIn'}/>
                    </span>
                </StatsWidget.Header>
                <StatsWidget.Body>
                    {deviceTypes.map(({ key, label }, index) => (
                        <React.Fragment key={key}>
                            <StatsWidgetNumber className='text-color-status-driving'
                                               value={renderValue(key, deviceCounts[key])} label={label}/>
                            {index !== deviceTypes.length - 1 && <StatsWidgetSpacer/>}
                        </React.Fragment>
                    ))}
                </StatsWidget.Body>
            </StatsWidget>
        </StatsWidgets>

    );
};

export default OverviewDevicesTypes;
