/* tslint:disable */
/* eslint-disable */
/**
 * OTA Admin Service
 * Old API to manage software deliverables and OTA updates
 *
 * OpenAPI spec version: v1
 * Contact: man-team-mdm@man.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { SemanticVersion } from '~/api/models/semantic-version';

/**
 * Search criteria for Update Events
 *
 * @export
 * @interface UpdateHistorySearchCriteria
 */
export interface UpdateHistorySearchCriteria {

    /**
     * device serial
     *
     * @type {string}
     * @memberof UpdateHistorySearchCriteria
     */
    hwSerial?: string;

    /**
     * name of deliverable, i.e., package name for package deliverables, 'distro' for distros
     *
     * @type {string}
     * @memberof UpdateHistorySearchCriteria
     */
    deliverableId?: string;

    /**
     * result of the update event
     *
     * @type {string}
     * @memberof UpdateHistorySearchCriteria
     */
    outcome?: string;

    /**
     * @type {SemanticVersion}
     * @memberof UpdateHistorySearchCriteria
     */
    actualDeliverableVersion?: SemanticVersion;

    /**
     * @type {SemanticVersion}
     * @memberof UpdateHistorySearchCriteria
     */
    targetDeliverableVersion?: SemanticVersion;

    /**
     * @type {SemanticVersion}
     * @memberof UpdateHistorySearchCriteria
     */
    actualBaseSoftwareVersion?: SemanticVersion;

    /**
     * @type {SemanticVersion}
     * @memberof UpdateHistorySearchCriteria
     */
    targetBaseSoftwareVersion?: SemanticVersion;

    /**
     * start of result timeframe (including)
     *
     * @type {Date}
     * @memberof UpdateHistorySearchCriteria
     */
    from?: Date;

    /**
     * end of result timeframe (including)
     *
     * @type {Date}
     * @memberof UpdateHistorySearchCriteria
     */
    to?: Date;

    /**
     * show only newest finished entry for each hwSerial, deliverableId, targetDeliverableVersion combination
     *
     * @type {boolean}
     * @memberof UpdateHistorySearchCriteria
     */
    showOnlyLastEvent?: boolean;

    /**
     * show also incomplete entries. that is updates not yet done (by a c4u call with success/failure report)
     *
     * @type {boolean}
     * @memberof UpdateHistorySearchCriteria
     */
    showIncompleteEvents?: boolean;

    /**
     * filters events with adapt order ongoing.
     *
     * @type {boolean}
     * @memberof UpdateHistorySearchCriteria
     */
    showAdaptOrderOngoingEvents?: boolean;

    /**
     * device serials list
     *
     * @type {Array<string>}
     * @memberof UpdateHistorySearchCriteria
     */
    hwSerialsList?: Array<string>;

    /**
     * bundle id
     *
     * @type {string}
     * @memberof UpdateHistorySearchCriteria
     */
    bundleId?: string;

    /**
     * @type {SemanticVersion}
     * @memberof UpdateHistorySearchCriteria
     */
    bundleVersion?: SemanticVersion;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateHistorySearchCriteriaOutcomeEnum {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    ONGOING = 'ONGOING',
    REBOOTPENDING = 'REBOOT_PENDING'
}

