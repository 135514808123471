import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DeviceTypeLabel } from '~/features/devices/components/DeviceType';
import DeviceProductionMode from '~/features/devices/components/DeviceProductionMode';
import DeviceState from '~/features/devices/components/DeviceState';
import DeviceDetailsToolbar from '~/features/devices/components/DeviceDetailsToolbar';
import fp from 'lodash/fp';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { connect } from 'react-redux';
import { toUpdateEventsScope } from '~/features/devices/components/DeviceDetailsContainer';
import { pathnameSelector } from '~/features/base/selectors/locationSelectors';
import {
    canReadControlDevicesSelector,
    canUpdateControlDevicesSelector,
    controlDeviceSelector,
    deviceDetailsUiSelector,
    getCurrentlySelectedDeviceSerialNumberSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import {
    canCreateDeviceActionsSelector,
    deviceActionSelector,
} from '~/features/deviceLogs/selectors/deviceActionSelectors';
import { canReadSysParamDefinitionsSelector } from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import {
    canTriggerCheck4UpdateSelector,
    isDeviceMonitorUserSelector,
} from '~/features/user/selectors/permissionSelectors';
import { updateEventsSuccessAndFailedSelector } from '~/features/updateEvents/selectors/updateEventSelectors';
import noop from 'lodash/fp/noop';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { triggerCheckForUpdateOnControlDevice } from '~/features/devices/actions/controlDeviceActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { fetchControlDeviceEligibleDeliverablesVersions } from '~/features/devices/actions/controlDeviceInfoActions';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import uid from '~/features/base/utils/uid';
import { getCM4C4U, verifyCM4DeviceOffline } from '~/features/base/utils/timeUtils';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import classNames from 'classnames';
import DeviceReplacedMode from '~/features/devices/components/dialogs/DeviceReplacedMode';

export class DeviceSummary extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCheckForUpdateTriggered: false,
            showCheckForUpdateProceeding: false,
        };
        this.name = uid();
        this.key = null;
    }

    isMdmcEligibleForCheckForUpdateTrigger = (packageMdmcAppVersion) => {
        if (packageMdmcAppVersion === '') {
            return false;
        }
        const version = Number(packageMdmcAppVersion.replace(/\./g, ''));
        return version >= 605 ? true : false;
    };

    onTriggerCheckForUpdate = () => {
        this.props.triggerCheckForUpdateOnControlDevice(this.props.serialNumber);
        this.setState({
            showCheckForUpdateTriggered: true,
            showCheckForUpdateProceeding: true,
        });
        setTimeout(() => this.setState({ showCheckForUpdateTriggered: false }), 2000);
        setTimeout(() => this.setState({ showCheckForUpdateProceeding: false }), 120000);
    };

    getPackageMdmcAppVersion = (controlDevice) => {
        let packageMdmcAppVersion = '6.0.5';
        if (controlDevice && controlDevice.lastPackageVersionsReported) {
            const packageMdmcApp = fp.find((currentPackage) => {
                return currentPackage.packageId === 'mdmc-app';
            }, controlDevice.lastPackageVersionsReported);
            if (packageMdmcApp && packageMdmcApp.packageVersion) {
                packageMdmcAppVersion = toShortSemanticVersion(packageMdmcApp.packageVersion);
            }
        }
        return packageMdmcAppVersion;
    };

    renderToolbar() {
        const {
            canUpdateControlDevices,
            controlDeviceInfo,
            serialNumber,
        } = this.props;

        let packageMdmcAppVersion = '';
        if (controlDeviceInfo && controlDeviceInfo.lastPackageVersionsReported) {
            const packageMdmcApp = fp.find((currentPackage) => {
                return currentPackage.packageId === 'mdmc-app';
            }, controlDeviceInfo.lastPackageVersionsReported);
            if (packageMdmcApp && packageMdmcApp.packageVersion) {
                packageMdmcAppVersion = toShortSemanticVersion(packageMdmcApp.packageVersion);
            }
        }

        if (!controlDeviceInfo) {
            return;
        }
        return (
            <DeviceDetailsToolbar
                controlDeviceInfo={controlDeviceInfo}
                serialNumber={serialNumber}
                packageMdmcAppVersion={packageMdmcAppVersion}
                canUpdateControlDevices={canUpdateControlDevices}
            />
        );
    }

    getVehicleIcon(controlDeviceInfo) {
        let vehicleIcon = 'rioglyph-truck';
        if (controlDeviceInfo.vehicleModel) {
            switch (controlDeviceInfo.vehicleModel) {
                case 'tge':
                    vehicleIcon = 'rioglyph-van';
                    break;
                case 'etge':
                    vehicleIcon = 'rioglyph-van';
                    break;
                default:
                    vehicleIcon = 'rioglyph-truck';
            }
        } else if (controlDeviceInfo.vin) {
            if (controlDeviceInfo.vin.startsWith('WMA') && controlDeviceInfo.vehicleVariant === 'fms4') {
                vehicleIcon = 'rioglyph-van';
            }
        }
        return vehicleIcon;
    }

    getDeviceStatusStyle(isDeviceOffline) {
        if (isDeviceOffline) {
            return 'badge-default';
        }
        return 'badge-success badge-indicator-pinging';
    }

    render() {
        const { controlDeviceInfo, canTriggerCheck4Update, controlDevice } = this.props;
        const packageMdmcAppVersion = this.getPackageMdmcAppVersion(controlDeviceInfo);
        const { showCheckForUpdateTriggered, showCheckForUpdateProceeding } = this.state;

        let vehicleIcon = this.getVehicleIcon(controlDeviceInfo);
        const isCM4DeviceOffline = verifyCM4DeviceOffline(controlDevice?.lastCheckForUpdateTimestamp, controlDeviceInfo?.installedDeliverables);

        if (controlDeviceInfo) {
            const type = controlDeviceInfo.type;
            const toolbar = this.renderToolbar();
            return (
                <div className='row'>
                    <div className='col-6 padding-bottom-20'>
                        <div className='row display-flex align-items-center'>
                            <div className='col-2 text-size-16 '>
                                <span
                                    className={`rioglyph ${vehicleIcon} text-size-300pct margin-top-20 margin-bottom-10 text-color-green`}
                                    aria-hidden='true'></span>
                            </div>
                            <div className='col-10'>
                                <div className='row text-color-darker'>
                                    <span className='text-bold text-size-20'>{controlDeviceInfo.serialNumber}</span>
                                    <OverlayTrigger placement='top' overlay={
                                        <Tooltip id='tooltip' className='top-right'>
                                            <FormattedMessage id='intl-msg:copySerialNumber'/>
                                        </Tooltip>}>
                                        <span onClick={() => copyToClipboard(controlDeviceInfo.serialNumber, true)}
                                              className='rioglyph rioglyph-duplicate text-color-dark text-size-20 padding-left-5'></span>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement='top' overlay={
                                        <Tooltip id='tooltip' className='top-right'>
                                            <FormattedMessage id='intl-msg:copyDirectDeviceLink'/>
                                        </Tooltip>}>
                                        <span onClick={() => copyToClipboard(
                                            `${window.location.origin}/#devices?serial-number-prefix=${controlDeviceInfo.serialNumber}&deviceSelected=true`,
                                            false)}
                                              className='rioglyph rioglyph rioglyph-link text-color-dark text-size-20 padding-left-5'></span>
                                    </OverlayTrigger>
                                </div>
                                <div className='row'>
                                    <DeviceTypeLabel deviceType={type}/>
                                    <DeviceProductionMode controlDevice={controlDeviceInfo}/>
                                    <DeviceReplacedMode replaced={controlDeviceInfo?.replaced}/>
                                    <DeviceState controlDevice={controlDeviceInfo}/>
                                </div>
                                <div className='row display-flex align-items-start padding-top-5'>
                                    {type === CM4 &&
                                        <OverlayTrigger placement='top' overlay={
                                            <Tooltip id='tooltip' className='top-right'>
                                                {isCM4DeviceOffline ?
                                                    <FormattedMessage id='intl-msg:deviceOffline' values={{
                                                        nMinutes: getCM4C4U(controlDeviceInfo?.installedDeliverables),
                                                    }}/> : <FormattedMessage id='intl-msg:online'/>}
                                            </Tooltip>}>
                                            <span
                                                className={classNames('position-relative margin-right-5 badge border-color-white border-width-2 border-style-solid', this.getDeviceStatusStyle(isCM4DeviceOffline))}/>
                                        </OverlayTrigger>
                                    }
                                    <span className='text-color-darker text-size-12'>

                                        <FormattedMessage id={'intl-msg:lastSeen'}/>:
                                        <span className='padding-left-5'>
                                            <FormattedTime value={controlDeviceInfo.lastCheckForUpdateTimestamp}
                                                           year='numeric'
                                                           month='2-digit' day='2-digit'/>
                                        </span>
                                        {
                                            showCheckForUpdateTriggered ?
                                                <span>
                                                    <OverlayTrigger placement='right' overlay={
                                                        <Tooltip id='tooltip' className='top-right'>
                                                            <div className='flex-row'>
                                                                        <span><FormattedMessage
                                                                            id='intl-msg:newTriggerCheckForUpdate'/></span>
                                                            </div>
                                                        </Tooltip>}>
                                                        <span className='padding-left-5'>
                                                            <span
                                                                className='rioglyph rioglyph-refresh spinning text-size-150pct'></span>
                                                        </span>
                                                    </OverlayTrigger>
                                                </span>
                                                : <span>
                                                    <OverlayTrigger placement='right' overlay={
                                                        <Tooltip id='tooltip' className='top-right'>
                                                            <div className='flex-row'>
                                                                {
                                                                    this.isMdmcEligibleForCheckForUpdateTrigger(
                                                                        packageMdmcAppVersion) ?
                                                                        canTriggerCheck4Update ?
                                                                            showCheckForUpdateProceeding ?
                                                                                <span><FormattedMessage
                                                                                    id='intl-msg:checkForUpdateProceeding'/></span>
                                                                                : <span><FormattedMessage
                                                                                    id='intl-msg:triggerCheckForUpdate'/></span>
                                                                            : <span><FormattedMessage
                                                                                id='intl-msg:missingPermissionsCheckForUpdate'/></span>
                                                                        : <span><FormattedMessage
                                                                            id='intl-msg:triggerCheckForUpdateDoesNotHaveMinimumVersion'/></span>
                                                                }
                                                            </div>
                                                        </Tooltip>}>
                                                        <span className='padding-left-5 position-relative bottom-1'>
                                                            {
                                                                (canTriggerCheck4Update
                                                                    && this.isMdmcEligibleForCheckForUpdateTrigger(
                                                                        packageMdmcAppVersion)) && <button
                                                                    disabled={showCheckForUpdateProceeding}
                                                                    onClick={this.onTriggerCheckForUpdate}
                                                                    className='btn btn-default btn-icon-only width-25 height-20'>
                                                                        <span
                                                                            className='rioglyph rioglyph-compare'></span>
                                                                </button>
                                                            }
                                                        </span>
                                                    </OverlayTrigger>
                                                </span>
                                        }
                                    </span>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-6 padding-bottom-20'>
                        {toolbar}
                    </div>
                </div>
            );
        }
        return <div></div>;
    }

    componentDidMount() {
        const serialNumber = this.props.serialNumber;
        if (!serialNumber || serialNumber === '---') {
            return;
        }
        this.registerDataInterest(serialNumber);

    }

    registerDataInterest(serialNumber) {
        this.props.registerDataInterest(this.name, [
            fetchControlDeviceEligibleDeliverablesVersions({ serialNumber }),

        ]);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.props.unregisterDataInterest(this.name);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        canTriggerCheck4Update: canTriggerCheck4UpdateSelector(state),
        canReadControlDevices: canReadControlDevicesSelector(state),
        canUpdateControlDevices: canUpdateControlDevicesSelector(state, ownProps),
        canCreateDeviceActions: canCreateDeviceActionsSelector(state),
        canReadSysParamDefinitions: canReadSysParamDefinitionsSelector(state),
        isLogLevelManager: isDeviceMonitorUserSelector(state),
        controlDevice: controlDeviceSelector(state, ownProps),
        deviceAction: deviceActionSelector(state, ownProps),
        updateEventsSuccessAndFailed: updateEventsSuccessAndFailedSelector(state,
            { scope: toUpdateEventsScope(ownProps.serialNumber) }),
        selectedSerialNumber: getCurrentlySelectedDeviceSerialNumberSelector(state),
        deviceDetails: deviceDetailsUiSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerCheckForUpdateOnControlDevice: (serialNumber) => {
            dispatch(triggerCheckForUpdateOnControlDevice({ serialNumber }));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSummary);

DeviceSummary.defaultProps = {
    // props
    serialNumber: '',
    packageMdmcAppVersion: '',
    controlDeviceInfo: undefined,
    canUpdateControlDevices: false,
    showCheckForUpdateTriggered: false,
    // functions
    onEditServiceLogLevel: noop,
    triggerCheckForUpdateOnControlDevice: noop,
};

DeviceSummary.propTypes = {
    // props
    serialNumber: PropTypes.string,
    packageMdmcAppVersion: PropTypes.string,
    controlDeviceInfo: PropTypes.object,
    canReadControlDevices: PropTypes.bool,
    showCheckForUpdateTriggered: PropTypes.bool,
    // functions
    onEditServiceLogLevel: PropTypes.func,
    triggerCheckForUpdateOnControlDevice: PropTypes.func,
};
