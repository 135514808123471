import React from 'react';
import UpdatesEventsOverview from '~/features/overview/components/OverviewUpdateEvents';
import OverviewVehicleVariants from '~/features/overview/components/OverviewVehicleVariants';
import OverviewDevicesTypes from '~/features/overview/components/OverviewDevicesTypes';
import OverviewTotalVehicles from '~/features/overview/components/OverviewTotalVehicles';

const OverviewDataSummary: React.FC = () => {

    return (
        <div className='display-grid'>
            <div className='display-grid gap-20 grid-cols-12'>
                <div className='bg-white  grid-colspan-2  display-flex align-items-center justify-content-center'>
                    <OverviewTotalVehicles/>
                </div>
                <div className='bg-white  grid-colspan-4  '>
                    <OverviewDevicesTypes/>
                </div>
                <div className='bg-white  grid-colspan-3'>
                    <UpdatesEventsOverview/>
                </div>
                <div className='bg-white bg-white   grid-colspan-3'>
                    <OverviewVehicleVariants/>
                </div>
            </div>
        </div>
    );

};

export default OverviewDataSummary;
