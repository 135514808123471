import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import { LOG_REQUESTS_PATH, OVERVIEW_PATH } from '~/features/base/constants/routes';

import PageHeader from '~/features/base/components/PageHeader';
import PageHeaderLink from '~/features/base/components/PageHeaderLink';

import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import DeviceListTable from '~/features/devices/components/DeviceListTable';

const columns = ['serialNumber', 'vin', 'hwVariant', 'softwareVersion',
    'lastCheckForUpdateTimestamp'];

/**
 * List of devices with logs requests
 */
export class DevicesWithLogsList extends PureComponent {

    render() {
        const content = this.renderContent();
        return (
            <div className='devices-with-logs-list row'>
                <PageHeader pageTitle={<FormattedMessage id='intl-msg:devicesWithLogs'/>}
                            pageLink={
                                <PageHeaderLink to={`/${OVERVIEW_PATH}`}>
                                    <FormattedMessage id='intl-msg:backToOverview'/>
                                </PageHeaderLink>
                            }
                            pageTitleLinkAddon={
                                <Link to={`/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}`}>
                                    <FormattedMessage id='intl-msg:logRequests'/>
                                    {' '}
                                    <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'></span>
                                </Link>
                            }/>
                {content}
            </div>
        );
    }

    renderContent() {
        const { controlDevices, pageCount, onShowItem, onLoadMore } = this.props;
        const showLoadMore = pageCount > controlDevices.length;
        return [
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
                <DeviceListTable
                    controlDevices={controlDevices}
                    hiddenColumns={[]}
                    columnOrder={columns}
                    canUpdateControlDevices={false}
                    allowSelection={true}
                    onShowItem={onShowItem}/>
            </DefaultWhiteColumn>,
            showLoadMore &&
            <DefaultWhiteColumn className='text-center padding-bottom-20'>
                <a id='load-more-button'
                   className='btn btn-default'
                   onClick={onLoadMore}>
                    <FormattedMessage id='intl-msg:loadMore'/>
                </a>
            </DefaultWhiteColumn>,
        ];
    }
}

export default DevicesWithLogsList;

DevicesWithLogsList.defaultProps = {
    // props
    controlDevices: [],
    pageCount: 0,
    // functions
    onShowItem: noop,
    onLoadMore: noop,
};

DevicesWithLogsList.propTypes = {
    // props
    controlDevices: PropTypes.array,
    pageCount: PropTypes.number,
    // functions
    onShowItem: PropTypes.func,
    onLoadMore: PropTypes.func,
};
