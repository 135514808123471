import React from 'react';
import OverviewMap from '~/features/overview/components/OverviewMap';
import OverviewRolloutsTile from '~/features/overview/components/OverviewRolloutsTile';

interface AllVehiclesInfoProps {}

const OverviewAllVehiclesInfo: React.FC<AllVehiclesInfoProps> = () => {

    return (
        <div className="overview-map">
            <div className="display-grid margin-top-20 gap-20 grid-cols-12">
                <div className="padding-20 grid-colspan-6 bg-white">
                    <OverviewMap/>
                </div>
                <div className="padding-20 grid-colspan-6 bg-white">
                    <OverviewRolloutsTile/>
                </div>
            </div>
        </div>
    );

};

export default OverviewAllVehiclesInfo;
