/* tslint:disable */
/* eslint-disable */
/**
 * OTA Client Api
 * API to manage software deliverables and OTA rollouts
 *
 * OpenAPI spec version: v2
 * Contact: man-team-mdm@man.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DistroVersion } from './distro-version';
import { RangeSemanticVersion } from './range-semantic-version';
import { SemanticVersion } from './semantic-version';
import { WhitelistingInfo } from './whitelisting-info';
/**
 * Search criteria for Vehicles
 * @export
 * @interface ControlDeviceSearchCriteria
 */
export interface ControlDeviceSearchCriteria {
    /**
     *
     * @type {DistroVersion}
     * @memberof ControlDeviceSearchCriteria
     */
    distroVersion?: DistroVersion;
    /**
     * Distro versions of control devices to be excluded
     * @type {Array<DistroVersion>}
     * @memberof ControlDeviceSearchCriteria
     */
    excludedDistroVersions?: Array<DistroVersion>;
    /**
     * Indicates whether a Control-Device is registered in a customer fleet
     * @type {boolean}
     * @memberof ControlDeviceSearchCriteria
     */
    inCustomerFleet?: boolean;
    /**
     * Beginning of serial number of control devices
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    serialNumberPrefix?: string;
    /**
     * Serial Numbers of the devices. There is a limit on how much entries are allowed
     * @type {Array<string>}
     * @memberof ControlDeviceSearchCriteria
     */
    serialNumbers?: Array<string>;
    /**
     * Beginning of vin of control devices
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    vinPrefix?: string;
    /**
     * Multiple vin of devices. There is a limit on how much entries are allowed
     * @type {Array<string>}
     * @memberof ControlDeviceSearchCriteria
     */
    vinList?: Array<string>;
    /**
     *
     * @type {SemanticVersion}
     * @memberof ControlDeviceSearchCriteria
     */
    softwareVersion?: SemanticVersion;
    /**
     * find devices where test releases are active/inactive
     * @type {boolean}
     * @memberof ControlDeviceSearchCriteria
     */
    testReleasesActive?: boolean;
    /**
     * find devices where SW updates are active/inactive
     * @type {boolean}
     * @memberof ControlDeviceSearchCriteria
     */
    updatesActive?: boolean;
    /**
     *
     * @type {WhitelistingInfo}
     * @memberof ControlDeviceSearchCriteria
     */
    whitelistingInfo?: WhitelistingInfo;
    /**
     * Beginning of hw variant of control devices
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    hwVariantPrefix?: string;
    /**
     * Multiple hw variants of devices. There is a limit on how much entries are allowed
     * @type {Array<string>}
     * @memberof ControlDeviceSearchCriteria
     */
    hwVariantList?: Array<string>;
    /**
     * Vehicle manufacturer spare part number of control device
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    vehicleManufacturerSparePartNumber?: string;
    /**
     * The high level device type
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    type?: ControlDeviceSearchCriteriaTypeEnum;
    /**
     * The imei of control devices
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    imei?: string;
    /**
     *
     * @type {SemanticVersion}
     * @memberof ControlDeviceSearchCriteria
     */
    mdmProtocolVersion?: SemanticVersion;
    /**
     *
     * @type {RangeSemanticVersion}
     * @memberof ControlDeviceSearchCriteria
     */
    mdmcAppVersionRange?: RangeSemanticVersion;
    /**
     * The last check for update was newer than given date
     * @type {Date}
     * @memberof ControlDeviceSearchCriteria
     */
    lastCheckForUpdateAfter?: Date;
    /**
     * List of vehicle variants as provided by rio.devices.info (unknown,ican,fms1,fms2,fms3,fms4,nhmr,vw,e4c)
     * @type {Array<string>}
     * @memberof ControlDeviceSearchCriteria
     */
    vehicleVariants?: Array<string>;
    /**
     * The device status (OK, WARN, ERROR)
     * @type {string}
     * @memberof ControlDeviceSearchCriteria
     */
    status?: ControlDeviceSearchCriteriaStatusEnum;
    /**
     * List of device status names(prod_mode, ota_network etc.)
     * @type {Array<string>}
     * @memberof ControlDeviceSearchCriteria
     */
    checkNames?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum ControlDeviceSearchCriteriaTypeEnum {
    TBM3 = 'TBM3',
    VCM = 'VCM',
    CM4 = 'CM4'
}
/**
    * @export
    * @enum {string}
    */
export enum ControlDeviceSearchCriteriaStatusEnum {
    OK = 'OK',
    WARN = 'WARN',
    ERROR = 'ERROR'
}

export type devicePropertiesModel = 'distroVersion' | 'softwareVersion' | 'hwVariantPrefix' | 'hwVariantList' | 'vehicleManufacturerSparePartNumber' | 'checknames';


