import React, { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { controlDevicePageItemsSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import { useSelector } from 'react-redux';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import { DEVICES_PATH } from '~/features/base/constants/routes';
import { stringifyToQuery } from '~/features/base/utils/query';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import AutoSuggest from '@rio-cloud/rio-uikit/AutoSuggest';

const routeMapper = {
    ['vin']: 'vin-list',
    ['serialNumber']: 'device-whitelist'
};

const suggestions = [{
    id: 10,
    label: 10,
    value: 10
},
    {
        id: 50,
        label: 50,
        value: 50
    },
    {
        id: 100,
        label: 100,
        value: 100
    },
    {
        id: 1000,
        label: 1000,
        value: 1000
    },
];
interface DeviceListSelectionDialogProps {
    onHide: () => void;
}

export const DeviceListSelectionDialog = ({ onHide }: DeviceListSelectionDialogProps) => {
    const [selectedOption, setSelectedOption] = useState<string>('serialNumber');
    const controlDevices = useSelector(controlDevicePageItemsSelector);
    const {
        control,
        formState: { errors, isValid },
        watch
    } = useForm({
        mode: 'onChange',
    });
    const deviceCount = watch('deviceCount');
    const highlightError = errors?.deviceCount?.message ? 'form-group has-feedback has-error' : '';

    const handleRadioChange = (value) => {
        setSelectedOption(value);
    };

    const getDevices = () => {
        if (!deviceCount || deviceCount <= 0 || deviceCount > controlDevices.length) {
            return;
        }
        return controlDevices
            .slice(0, deviceCount)
            .map(device => device[selectedOption]);

    };

    const handleClickOnClipboard = () => {
        const devices = getDevices();
        copyToClipboard(devices.join(','), false);
        onHide();
    };
    const handleOpenInNewTab = () => {
        const devices = getDevices();
        if (devices.length > 0) {
            const route = `/#${DEVICES_PATH}?${stringifyToQuery({ [routeMapper[selectedOption]]: devices })}`;
            onHide();
            return openInNewTab(route);
        }
    };

    const renderBody = () => {
        return (
            <form>
                <label> <FormattedMessage id='intl-msg:by'/>:
                </label>
                <div>
                    <RadioButton name='contactOptions' value='serialNumber' checked={selectedOption === 'serialNumber'}
                                 onChange={() => handleRadioChange('serialNumber')} inline>
                        <FormattedMessage id='intl-msg:serialNumber'/>s
                    </RadioButton>
                    <RadioButton name='by' checked={selectedOption === 'vin'} onChange={() => handleRadioChange('vin')}
                                 value='vin' inline>
                        <FormattedMessage id='intl-msg:VIN'/>s
                    </RadioButton>

                </div>
                <div className={classNames('width-200 margin-top-20', highlightError)}>
                    <label htmlFor='no-default'><FormattedMessage id={'intl-msg:numberOfDevices'}/>: </label>
                    <Controller
                        name='deviceCount'
                        control={control}
                        rules={{
                            required: 'This field is required',
                            min: { value: 1, message: 'Number must be at least 1' },
                            max: { value: controlDevices.length, message: 'Cannot exceed devices loaded' },
                            pattern: { value: /^[0-9]+$/, message: 'Only numeric values are allowed' }
                        }}
                        render={({ field }) => (
                            <>
                                <AutoSuggest
                                    className={'margin-bottom-0'}
                                    {...field}
                                    suggestions={suggestions}
                                    inputProps={{
                                        value: deviceCount,
                                        onClear: field.onChange,
                                        onChange: (_, changeProps) => (field.onChange(changeProps.newValue)),

                                    }}
                                />
                                {errors.deviceCount && (
                                    <div
                                        className='help-block'>
                                        <span>{errors?.deviceCount?.message as string}</span></div>
                                )}
                            </>)}
                    />

                </div>
            </form>
        );
    };
    const renderFooter = () => {
        return <div className='btn-toolbar justify-content-end'>
            <button type='button' className='btn btn-default' onClick={handleOpenInNewTab} disabled={!isValid}>
                <span className='rioglyph rioglyph
rioglyph rioglyph-new-window  padding-right-5'
                      aria-hidden='true'></span>
                <FormattedMessage id='intl-msg:openInNewTab'/>
            </button>
            <button type='button' className='btn btn-default ' onClick={handleClickOnClipboard} disabled={!isValid}>
                 <span className='rioglyph rioglyph rioglyph-duplicate  padding-right-5'
                       aria-hidden='true'></span>
                <FormattedMessage id='intl-msg:copyToClipboard'/>
            </button>
        </div>;
    };

    return (
        <Dialog
            show={true}
            showCloseButton={true}
            onHide={onHide}
            bodyClassName={'visible'}
            title={<FormattedMessage id='intl-msg:selectDevices'/>}
            body={renderBody()}
            footer={renderFooter()}

        />
    );
};
