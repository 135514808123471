import { createApi, } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import { UpdateHistorySearchCriteria } from '~/api/updateHistory/update-history-search-criteria';
import { ResponseValueCountLong } from '~/api/models/response-value-count-long';

const baseUrl: string = '/v1/admin/update-history';
export const updateHistoryApi = createApi({
    reducerPath: 'updateHistoryApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        fetchUpdatesCount: builder.query<number, UpdateHistorySearchCriteria>({
            query: (criteria) => ({
                url: `${baseUrl}/count`,
                method: 'POST',
                body: criteria,
            }),
            transformResponse: (data: ResponseValueCountLong) => data.count,

        }),

    }),
});

export const { useLazyFetchUpdatesCountQuery } = updateHistoryApi;
