import React from 'react';
import { FormattedMessage } from 'react-intl';
import {LOG_REQUESTS_PATH, OVERVIEW_PATH} from "~/features/base/constants/routes";
import {Link} from "react-router-dom";

interface OverviewFooterProps {
}

const OverviewFooter: React.FC<OverviewFooterProps> = () => {
    return (
        <div className='overview-footer display-grid margin-top-20'>
            <div className=' text-start text-size-h2 text-bold margin-bottom-10' style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                <FormattedMessage id={'intl-msg:quickLinks'}/>
            </div>
            <div className='display-grid margin-top-10 gap-20 grid-cols-12'>
                <div className='bg-white padding-15 grid-colspan-6'>
                    <div className=' text-start text-bold text-size-h6 margin-bottom-10' style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                        <FormattedMessage id='intl-msg:product'/>
                    </div>
                    <div className='row gap-10'>
                        <div className='col-4'>
                            <a href='https://confluence.collaboration-man.com/display/RIOCLAID/MDM+OTA+Documentation'
                               target='_blank'
                               rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:otaIntroduction'/>
                            </span>
                            </a>
                        </div>
                        <div className='col-4'>
                            <a href='https://confluence.collaborationlayer-traton.com/display/MDM/MDM+Knowledge+Base'
                               target='_blank'
                               rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:otaKnowledgeBase'/>
                            </span>
                            </a>
                        </div>
                        <div className='col-4'>
                            <Link to={`/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}`}>
                                <FormattedMessage id='intl-msg:openLogRequests'/>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='bg-white padding-15 grid-colspan-6'>
                    <div className=' text-start text-bold text-size-h6 margin-bottom-10' style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                        <FormattedMessage id='intl-msg:support'/>
                    </div>
                    <div className='row gap-10'>
                        <div className='col-6'>
                            <a href='https://jiratoolchain.collaborationlayer-traton.com/projects/MDMOPS'
                               target='_blank'
                               rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:createJiraTicket'/>
                            </span>
                            </a>
                        </div>
                        <div className='col-6'>
                            <a href='https://my-rio.slack.com/archives/C015W6RRNAX'
                               target='_blank'
                               rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:mdmSupportChannel'/>
                            </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverviewFooter;
