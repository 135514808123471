import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import Group from '~/prop-types/groupPropType';

import React, { PureComponent } from 'react';
import DeviceTypeFormItem from '~/features/base/components/forms/DeviceTypeFormItem';
import SuggestFormItem from '~/features/base/components/forms/SuggestFormItem';
import WhitelistingDetailsEditorFormItem from '~/features/base/components/forms/WhitelistingDetailsEditorFormItem';
import ActivatedFormItem from '~/features/base/components/forms/ActivatedFormItem';
import BlockedFormItem from '~/features/base/components/forms/BlockedFormItem';
import TestReleaseActiveFormItem from '~/features/base/components/forms/TestReleaseActiveFormItem';
import { parseQuery } from '~/features/base/utils/query';
import compact from 'lodash/fp/compact';
import split from 'lodash/fp/split';
import defaults from 'lodash/fp/defaults';
import {
    createBaseSwVersionOptions,
    createDistroVersionOptions,
    createHwVariantOptions,
    createVehicleSparePartNumberOptions,
} from '~/features/base/constants/filterOptions';
import { connect } from 'react-redux';
import { searchSelector } from '~/features/base/selectors/locationSelectors';
import { distroVersionsSelector } from '~/features/deliverables/features/distros/selectors/distroSelectors';
import { baseSwVersionsSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';
import { hwVariantsSelector } from '~/features/hwVariants/selectors/hwVariantsSelectors';
import {
    vehicleSparePartNumbersSelector,
} from '~/features/vehicleSparePartNumbers/selectors/vehicleSparePartNumbersSelectors';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';
import {
    deviceStatusNamesSelector,
    deviceStatusTypesSelector,
    filterSettingsControlDevicesSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import VehicleVariantsFormItem from '~/features/base/components/forms/VehicleVariantsFormItem';
import { vehicleVariantsSelector } from '~/features/vehicleVariants/selectors/vehicleVariantsSelectors';
import HardwareVariantsFormItem from '~/features/base/components/forms/HardwareVariantsFormItem';
import DeviceStatusFormItem from '~/features/base/components/forms/DeviceStatusFormItem';
import { unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { fetchFilteredDistros } from '~/features/deliverables/features/distros/actions/distroActions';
import { TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import EssentialImageFormItem from '~/features/base/components/forms/EssentialImageFormItem';
import InstalledDeliverablesFormGroup from '~/features/groups/components/InstalledDeliverablesFormGroup';
import { fetchAllDeliverableIds } from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
} from '~/features/deliverables/constants/deliverablesParameters';
import { installedDeliverablesSelector } from '~/features/deliverables/selectors/deliverableSelectors';
import { InstalledDeliverableRange } from '~/features/deliverables/utils/InstalledDeliverableRange';
import MileageFormItem from '~/features/base/components/forms/MileageFormItem';
import ReplacedFormItem from '~/features/base/components/forms/ReplacedFormItem';

/**
 * Control device filter form
 */
export class DeviceFilterForm extends PureComponent {
    render() {
        const {
            distros,
            deviceStatusNames,
            deviceStatusTypes,
            baseSoftwareVersions,
            hwVariants,
            vehicleSparePartNumbers,
            groups,
            search,
            onHwVariantFilterChange,
            onHwVariantListFilterChange,
            onDeviceStatusNamesChange,
            onDeviceStatusTypeChange,
            onDeviceTypeFilterChange,
            onDistroVersionFilterChange,
            onBaseSwVersionFilterChange,
            onWhitelistingInfoChange,
            onVehicleSparePartNumberChange,
            onInCustomerFleetFilterChange,
            onUpdatesActiveFilterChange,
            onVehicleVariantFilterChange,
            onTestReleasesActiveFilterChange,
            onLastEssentialImageReportedChange,
            onMinMileageChange,
            onMaxMileageChange,
            onInstalledDeliverablesChange,
            onReplaceFilterChange,
            deliverables,
            defaultFilters,
        } = this.props;
        const parsedQuery = parseQuery(search);
        const properQuery = {
            ...parsedQuery,
            excludeDistroVersions: compact(split(',', parsedQuery.excludeDistroVersions)),
            vehicleGroupWhitelist: compact(split(',', parsedQuery.vehicleGroupWhitelist)),
            vehicleVariantsWhitelist: compact(split(',', parsedQuery.vehicleVariantsWhitelist)),
            hwVariantList: compact(split(',', parsedQuery.hwVariantList)),
            deviceWhitelist: compact(split(',', parsedQuery.deviceWhitelist)),
            vinList: compact(split(',', parsedQuery.vinList)),
            vehicleGroupBlacklist: compact(split(',', parsedQuery.vehicleGroupBlacklist)),
            deviceBlacklist: compact(split(',', parsedQuery.deviceBlacklist)),
            installedDeliverables: split(',', parsedQuery.installedDeliverables).map(d => InstalledDeliverableRange.create(d)),
            lastEssentialImageReported: { version: parsedQuery.lastEssentialImageReportedVersion },
            minMileage: compact(split(',', parsedQuery.minMileage)),
            maxMileage: compact(split(',', parsedQuery.maxMileage)),

        };
        const defaultedQuery = defaults(defaultFilters, properQuery);
        const baseSwVersionOptions = createBaseSwVersionOptions(baseSoftwareVersions);
        const distroVersionOptions = createDistroVersionOptions(distros);
        const hwVariantOptions = createHwVariantOptions(hwVariants);
        const vehicleSparePartNumberOptions = createVehicleSparePartNumberOptions(vehicleSparePartNumbers);
        const isTmb3OrVcm = defaultedQuery.deviceType === TBM3 || defaultedQuery.deviceType === VCM || defaultedQuery.deviceType === undefined;
        return (
            <form>
                <SuggestFormItem value={defaultedQuery.hwVariant}
                                 label='intl-msg:hwVariantPrefix'
                                 options={hwVariantOptions}
                                 onChange={onHwVariantFilterChange}/>
                <HardwareVariantsFormItem
                    whiteListedHardwareVariants={defaultedQuery.hwVariantList}
                    hardwareVariants={hwVariants}
                    onChange={onHwVariantListFilterChange}/>
                <EssentialImageFormItem value={defaultedQuery.lastEssentialImageReported}
                                        onChange={onLastEssentialImageReportedChange}/>
                <InstalledDeliverablesFormGroup values={defaultedQuery.installedDeliverables}
                                                deliverables={deliverables}
                                                onChange={onInstalledDeliverablesChange}/>
                <MileageFormItem onMinMileageChange={onMinMileageChange} onMaxMileageChange={onMaxMileageChange} maxMileage={defaultedQuery.maxMileage} minMileage={defaultedQuery.minMileage}/>
                <DeviceTypeFormItem value={defaultedQuery.deviceType}
                                    showOptionAll={true}
                                    onChange={onDeviceTypeFilterChange}/>
                <DeviceStatusFormItem deviceStatusNames={deviceStatusNames}
                                      deviceStatusTypes={deviceStatusTypes}
                                      valueDeviceStatusNames={defaultedQuery.deviceStatusNames}
                                      valueDeviceStatusTypes={defaultedQuery.deviceStatusType}
                                      onDeviceStatusNamesChange={onDeviceStatusNamesChange}
                                      onDeviceStatusTypeChange={onDeviceStatusTypeChange}/>
                {isTmb3OrVcm &&
                    <>
                    <SuggestFormItem value={defaultedQuery.distroVersion}
                                 label='intl-msg:distroVersion'
                                 options={distroVersionOptions}
                                 onChange={onDistroVersionFilterChange}
                                 disabled
                />
                 <SuggestFormItem value={defaultedQuery.swVersion}
                                                 label='intl-msg:baseSwVersion'
                                                 options={baseSwVersionOptions}
                                                 onChange={onBaseSwVersionFilterChange}
                                                 disabled
                />
                    </>
                }
                <VehicleVariantsFormItem
                    whiteListedVehicleVariants={defaultedQuery.vehicleVariantsWhitelist}
                    onChange={onVehicleVariantFilterChange}/>
                <ReplacedFormItem value={defaultedQuery.replaced} onChange={onReplaceFilterChange}/>
                <ActivatedFormItem value={defaultedQuery.inCustomerFleet}
                                   onChange={onInCustomerFleetFilterChange}/>
                <BlockedFormItem value={defaultedQuery.updatesActive}
                                 onChange={onUpdatesActiveFilterChange}/>
                <TestReleaseActiveFormItem value={defaultedQuery.testReleasesActive}
                                           onChange={onTestReleasesActiveFilterChange}/>
                <WhitelistingDetailsEditorFormItem whitelistingInfo={defaultedQuery}
                                                   groups={groups}
                                                   deviceWhitelistLabel='intl-msg:includeDevices'
                                                   groupWhitelistLabel='intl-msg:includeGroups'
                                                   deviceBlacklistLabel='intl-msg:excludeDevices'
                                                   groupBlacklistLabel='intl-msg:excludeGroups'
                                                   onWhitelistingInfoChange={onWhitelistingInfoChange}/>
                <SuggestFormItem value={defaultedQuery.vehicleManufacturerSparePartNumber}
                                 label='intl-msg:manufacturerSparePartNumber'
                                 options={vehicleSparePartNumberOptions}
                                 onChange={onVehicleSparePartNumberChange}/>
            </form>
        );
    }

    componentWillMount() {
        this.props.fetchFilteredDistros({
            page: 0,
            size: 1000,
            searchCriteria: {},
        });
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_APP);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_CM4G);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_BUNDLE);
    }
}

const mapStateToProps = (state, ownProps) => ({
    search: searchSelector(state),
    distros: distroVersionsSelector(state, ownProps),
    baseSoftwareVersions: baseSwVersionsSelector(state),
    hwVariants: hwVariantsSelector(state),
    deviceStatusNames: deviceStatusNamesSelector(state),
    deviceStatusTypes: deviceStatusTypesSelector(state),
    vehicleSparePartNumbers: vehicleSparePartNumbersSelector(state),
    groups: groupsSelector(state),
    vehicleVariants: vehicleVariantsSelector(state),
    previousFilterSettings: filterSettingsControlDevicesSelector(state),
    deliverables: installedDeliverablesSelector(state),

});

const mapDispatchToProps = dispatch => ({
    fetchFilteredDistros: (payload) => {
        dispatch(fetchFilteredDistros(payload));
    },
    fetchFilteredDeliverables: (deliverableType) => {
        dispatch(fetchAllDeliverableIds(
            {
                page: 0,
                searchCriteria: {
                    deliverableType: deliverableType,
                },
            },
        ));
    },
    unregisterDataInterest: (name) => {
        dispatch(unregisterDataInterest(name));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceFilterForm);

DeviceFilterForm.defaultProps = {
    // props
    groups: [],
    defaultFilters: {},
    // functions
    fetchFilteredDistros: noop,
    changeControlDeviceEditor: noop,
    onHwVariantFilterChange: noop,
    onDeviceStatusNamesChange: noop,
    onDeviceStatusTypeChange: noop,
    onHwVariantListFilterChange: noop,
    onDeviceTypeFilterChange: noop,
    onDistroVersionFilterChange: noop,
    onBaseSwVersionFilterChange: noop,
    onWhitelistingInfoChange: noop,
    onVehicleSparePartNumberChange: noop,
    onInCustomerFleetFilterChange: noop,
    onUpdatesActiveFilterChange: noop,
    onVehicleVariantFilterChange: noop,
    onTestReleasesActiveFilterChange: noop,
};

DeviceFilterForm.propTypes = {
    // props
    groups: PropTypes.arrayOf(Group),
    defaultFilters: PropTypes.object,
    // functions
    fetchFilteredDistros: PropTypes.func,
    changeControlDeviceEditor: PropTypes.func,
    onHwVariantFilterChange: PropTypes.func,
    onDeviceStatusNamesChange: PropTypes.func,
    onDeviceStatusTypeChange: PropTypes.func,
    onHwVariantListFilterChange: PropTypes.func,
    onDeviceTypeFilterChange: PropTypes.func,
    onDistroVersionFilterChange: PropTypes.func,
    onBaseSwVersionFilterChange: PropTypes.func,
    onWhitelistingInfoChange: PropTypes.func,
    onVehicleSparePartNumberChange: PropTypes.func,
    onInCustomerFleetFilterChange: PropTypes.func,
    onUpdatesActiveFilterChange: PropTypes.func,
    onVehicleVariantFilterChange: PropTypes.func,
    onTestReleasesActiveFilterChange: PropTypes.func,
};
