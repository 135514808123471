import React, { useState, useMemo } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import { FormattedMessage } from 'react-intl';
import { useFetchVehiclesByCountryQuery } from "~/api/controlDevice/ControlDevice.api";
import { env } from "~/env";
import {abbreviateNumber} from "~/features/base/utils/abbreviateNumber";
import {mapFeatures} from "~/features/overview/constants/features";

const OverviewMap: React.FC = () => {
    const [hoveredCountry, setHoveredCountry] = useState<{ name: string; vehicles: number } | null>(null);


    const { data } = useFetchVehiclesByCountryQuery({});
    const { networkCodes, mapScale } = env.runtimeConfig;


    const mergeCountryData = (
        vehicles: Record<string, number>,
        countryInfo: Record<string, { name: string; iso2: string; coordinates: [number, number] }>
    ) => {
        let unknownCount = 0;
        let result: {
            name: string;
            code: string;
            iso2: string;
            coordinates: [number, number];
            vehicles: number;
        }[] = [];

        result = Object.keys(vehicles)
            .map(code => {
                const country = countryInfo[code];

                if (!country) {
                    unknownCount += vehicles[code] || 0;
                    return null;
                }

                return {
                    name: country.name,
                    code,
                    iso2: country.iso2,
                    coordinates: country.coordinates,
                    vehicles: vehicles[code] || 0
                };
            })
            .filter((entry): entry is {
                name: string;
                code: string;
                iso2: string;
                coordinates: [number, number];
                vehicles: number;
            } => entry !== null);

        if (unknownCount > 0) {
            result.push({
                name: "Unknown",
                code: "000",
                iso2: "xx",
                coordinates: [0.0, 0.0],
                vehicles: unknownCount
            });
        }

        return result;
    };

    const markers = useMemo(() => (data && networkCodes ? mergeCountryData(data, networkCodes) : []), [data, networkCodes]);

    const minVehicles = Math.min(...markers.map(m => m.vehicles));
    const maxVehicles = Math.max(...markers.map(m => m.vehicles));

    const interval1 = Math.ceil(minVehicles);
    const interval2 = Math.ceil((maxVehicles - minVehicles) / 3) + interval1;
    const interval3 = maxVehicles;

    const colorScale = scaleLinear<string>()
        .domain([interval1, interval2, interval3])
        .range(["#3690ae14", "#3690aeb0", "rgb(54, 144, 174)"]);

    return (
        <div style={{ position: "relative" }}>
            <div className=' text-start text-size-h5 text-bold'
                 style={{ fontFamily: 'MANEUROPECONDENSED' }}> <FormattedMessage id={'intl-msg:overview.mapTitle'} />
            </div>
            <ComposableMap
                projectionConfig={mapScale}
                style={{ width: "100%", height: "500px" }}
            >
                <Geographies geography={mapFeatures}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const countryName = geo.properties.name;
                            const vehicleCount = markers?.find(country => country?.name === countryName)?.vehicles || 0;
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    onMouseEnter={() => setHoveredCountry({ name: countryName, vehicles: vehicleCount })}
                                    onMouseLeave={() => setHoveredCountry(null)}
                                    style={{
                                        default: {
                                            fill: colorScale(vehicleCount),
                                            outline: "none",
                                            stroke: "#FFF",
                                            strokeWidth: 0.5
                                        },
                                        hover: { fill: "#dce2e8", outline: "none" },
                                    }}
                                />
                            );
                        })
                    }
                </Geographies>
                {hoveredCountry && markers?.find(m => m.name === hoveredCountry.name) && (
                        <Marker coordinates={markers?.find(m => m.name === hoveredCountry.name)?.coordinates || [10, 50]}>
                        <text
                            x={0}
                            y={-30}
                            textAnchor="middle"
                            style={{
                                fontFamily: "MANEUROPECONDENSED",
                                fontSize: 14,
                                fill: "#333",
                                fontWeight: "bold",
                                background: "black",
                                padding: "5px",
                                borderRadius: "4px",
                                boxShadow: "0px 0px 5px rgba(0,0,0,0.3)"
                            }}
                        >
                            {hoveredCountry.name}
                        </text>
                    </Marker>
                )}
                {(markers).map(({ name, coordinates, vehicles }) => (
                            <Marker key={name} coordinates={coordinates}>
                                <circle r={5} fill="black" stroke="#fff" strokeWidth={2} />
                                <text
                                    textAnchor="middle"
                                    y={25}
                                    style={{ fontFamily: "MANEUROPECONDENSED", fill: "#5D5A6D" }}
                                >
                                    {abbreviateNumber(vehicles)}
                                </text>
                            </Marker>
                        ))}
            </ComposableMap>
            <div className=' text-start text-size-14 text-bold margin-top-10'
                 style={{ fontFamily: 'MANEUROPECONDENSED' }}> <FormattedMessage id={'intl-msg:legend'} />
            </div>
            <div className="display-flex gap-20 margin-top-5">
                <div className="display-flex align-items-center">
                    <div className="uk-border-rounded" style={{ width: "14px", height: "14px", background: colorScale(interval1), marginRight: "6px" }}>
                    </div>
                    <span className={'text-size-14'} style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                        <FormattedMessage id="intl-msg:overview.map.minRangeLegend" values={{max: abbreviateNumber(interval2)}}/>
                    </span>
                </div>
                <div className="display-flex align-items-center">
                    <div className="uk-border-rounded" style={{ width: "14px", height: "14px", background: colorScale(interval2), marginRight: "6px" }}></div>
                        <span className={'text-size-14'} style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                            <FormattedMessage id="intl-msg:overview.map.midRangeLegend" values={{
                                min: abbreviateNumber(interval2),
                                max: abbreviateNumber(interval3)
                            }}/>
                        </span>
                    </div>

                <div className="display-flex align-items-center">
                    <div className="uk-border-rounded" style={{ width: "14px", height: "14px", background: colorScale(interval3), marginRight: "6px" }}></div>
                    <span className={'text-size-14'} style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                        <FormattedMessage id="intl-msg:overview.map.maxRangeLegend" values={{max: abbreviateNumber(interval3)}}/>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default OverviewMap;
