import React from 'react';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { FormattedMessage } from 'react-intl';
import { ALL, replacedOptions } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

interface ExcludeReplacedDevicesToggleProps {
    onChange: (item: { replaced: boolean | undefined }) => void;
    value: boolean | undefined;
    withSeparator: boolean;
}

const ReplacedFormItem: React.FC<ExcludeReplacedDevicesToggleProps> = ({
    onChange, value, withSeparator
}) => {

    const handleChange = (option) => {
        onChange({
            replaced: option.id === ALL ? undefined : option.value,
        });
    };
    const optionsWithSelection = selectionByValue(value === undefined ? ALL : value)(replacedOptions);

    return (
        <div className='form-group'>
            <label className='control-label'>
                <FormattedMessage id={'intl-msg:replacedLabel'}/>
            </label>
            <OverlayTrigger
                key={1}
                placement={'right-start'}
                overlay={
                    <Tooltip id='tooltip' allowOnTouch>
                        <FormattedMessage id={'intl-msg:replacedSelectTooltip'}/>
                    </Tooltip>
                }
            >
                <span className='rioglyph rioglyph-info-sign margin-left-5 label-info' style={{ fontSize: '16px' }}
                      aria-hidden='true'></span>
            </OverlayTrigger>
            <Select options={optionsWithSelection}
                    onChange={handleChange}/>
            {withSeparator &&
                <div className={'border border-color-grey border-top-only border-width-1  margin-top-20'}/>}
        </div>
    );
};

export default ReplacedFormItem;
